import { Component } from '@angular/core';
import { ColoredCard } from 'src/app/models/colored-card';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';



@Component({
  selector: 'multiple-colored-blocks-root',
  templateUrl: './multiple-colored-blocks.component.html',
  styleUrls: ['./multiple-colored-blocks.component.scss']
})

export class MultipleColoredBlocksComponent extends BaseSectionComponent  {

  name: string = "Multiple Colored Blocks";
  componentGql: string = `... on ComponentContentMultipleColoredBlocks { title background_styling { color } cards { ... on ComponentItemColoredCard { title subtitle description color filled text_color side } } }`;

  title: string = "";
  num: string = "";
  items: ColoredCard[] = [];

  count!: number;
  
  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;

    this.sectionData.cards.forEach( (item: { title: string; subtitle: string; description: string; side: string; color: string; text_color: string; filled: boolean; }) => {
      this.items.push({
        title: item.title,
        subtitle: item.subtitle,
        description: item.description?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm).filter(x => { return x }),
        side: item.side,
        color: item.color,
        text_color: item.text_color,
        filled: item.filled
      })
    })

    this.count = this.items.length;
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
