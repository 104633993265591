<div class="bg-white sticky-top">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a class="navbar-brand text-decoration-none me-5" routerLink="/" aria-label="ZWS Home"><img class="img__logo zoom" src="/assets/logo.svg" alt="ZWS logo" /></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div #navbarContent class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="navbar-nav">
            <div *ngFor="let item of navigationItems" class="me-2">
              <a *ngIf="item.navigation_items.length == 0" [routerLink]="item.path" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" class="nav-link text-decoration-none link__nav link__nav__hover" [ngClass]="{'special': item.special }" (click)="collapseNav()">
              {{ item.name }}
                <span class="sr-only">(current)</span>
              </a>
              <div *ngIf="item.navigation_items.length > 0" class="nav-item dropdown dropdown-navigation">
                <a class="nav-link link__nav dropdown-toggle" [routerLink]="item.path" routerLinkActive="is-active" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ item.name }}
                </a>
                <ul class="dropdown-menu rounded-0 bg-white me-lg-3" [ngClass]="['border-start-' + item.color]">
                  <li *ngFor="let subitem of item.navigation_items" class="ms-3 ms-lg-1" (click)="collapseNav()">
                    <a [routerLink]="subitem.path" routerLinkActive="is-active" class="nav-link menu-nav-item p-0 my-2 ms-2 text-decoration-none link__nav">
                    {{ subitem.name }}
                      <span class="sr-only">(current)</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="d-flex float-end ms-auto">
            <div class="dropdown">
              <a class="btn btn-white" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <fa-icon [icon]="faGlobe" class="fa__external" rel="noopener"></fa-icon> {{currentLanguage.display}}
              </a>
              <ul class="dropdown-menu border-1 bg-white" aria-labelledby="dropdownMenuLink">
                <li *ngFor="let language of languages">
                  <div class="dropdown-item language-item" (click)="changeLanguage(language)"><span class="flag-icon flag-icon-{{language.flag}}"></span>{{language.display}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

