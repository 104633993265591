<div *ngIf="isLoaded" class="container__banner" [style]="'special' | BackgroundPipe">
    <div class="container">
        <div *ngIf="!success" class="row justify-content-md-center py-5 px-2">
            <div class="col-sm-6 order-sm-1 d-flex flex-column">
                <div class="row mx-3 mb-2">
                    <h2 style="font-weight: bold;" [ngClass]="{'text-light': contrast === 'light'}">{{ title }}</h2>
                </div>
                <div class="row mx-3" [ngClass]="{'text-light': contrast === 'light'}">
                    <div *ngFor="let block of blocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6" [ngClass]="{'order-sm-2': side === 'right'}">
                <div class="row align-items-center g-lg-5 py-5 py-sm-0 mx-3 mx-sm-0 d-inline">
                    <form *ngIf="fields.length > 0" class="needs-validation mt-0" (ngSubmit)="captchaRef.execute()" [formGroup]="form" novalidate>
                        <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" siteKey="{{recaptchaSiteKey}}" size="invisible"></re-captcha>      
                        <div *ngFor="let field of fields" class="col-12">
                            <form-item-root [field]="field" [form]="form"></form-item-root>
                        </div>
                        <div *ngIf="errorMessage" class="form-row">
                            <p [ngClass]="{'text-light': contrast === 'light'}">{{ errorMessage }}</p>
                        </div>
                        <div *ngIf="form" class="form-row mt-5">
                            <button type="submit" class="btn btn-outline-light px-4 rounded-0 border-2 ms-2" [disabled]="form.invalid || loading">{{ submitText }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="success" class="justify-content-md-center py-5 px-2" [@expandCollapseAnimation]>
            <div class="align-items-center g-lg-5 py-5">
                <div class="row mx-3 mb-2">
                    <h1 style="font-weight: bold;" [ngClass]="{'text-light': contrast === 'light'}">{{ successTitle }}</h1>
                </div>
                <div class="row mx-3" [ngClass]="{'text-light': contrast === 'light'}">
                    <div *ngFor="let block of successBlocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="swishQrCode" class="text-center" style="height: 25rem;" id="swish" [@inOutAnimation]>
    <img class="img-fluid h-100" [src]="swishQrCode" alt="Swish QR-Code"/>
</div>

