<div class="container" [hidden]="!isVisible">
  <div class="container-fluid justify-content-center text-dark ">
    <footer>
      <div class="row my-3 justify-content-start py-3">
        <div class="col-3 col-md-2 col-lg-1 order-1 text-sm-end text-end">
          <div class="row my-4 justify-content-end">
            <a class="h4 d-inline-block" routerLink="/"><img class="img__logo" src="/assets/logo.svg" alt="ZWS logo" /></a>
          </div>
        </div>
        <div class="col-9 col-md-4 col-lg-4 order-1">
          <div class="row my-3 justify-content-start">
            <div class="d-block">
              <h3 class="text-black mb-md-0 mb-1 fw-bolder text-start">Thank you.</h3>
            </div>
            <div class="d-block my-2 text-start">
              <div class="px-0">
                <a class="me-3 my-1 d-inline-block" href="https://www.facebook.com/zerowastestockholm/" rel="noopener"><img src="/assets/icons/facebook.svg" class="fa__external" alt="Facebook"/></a>
                <a class="me-3 my-1 d-inline-block" href="https://www.instagram.com/zerowaste.stockholm/" rel="noopener"><img src="/assets/icons/instagram.svg" class="fa__external" alt="Instagram"/></a>
                <a class="me-3 my-1 d-inline-block" href="https://www.linkedin.com/company/zero-waste-stockholm/" rel="noopener"><img src="/assets/icons/linkedin.svg" class="fa__external" alt="LinkedIn"/></a>
                <a class="me-3 my-1 d-inline-block" href="https://www.meetup.com/Zero-Waste-Stockholm/" rel="noopener"><img src="/assets/icons/meetup.svg" class="fa__external" alt="Meetup"/></a>
                <a class="me-3 my-1 d-inline-block" href="https://linktr.ee/zerowastestockholm" rel="noopener"><img src="/assets/icons/linktree.svg" class="fa__external" alt="Linktree"/></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-7 row order-md-2 mb-3 justify-content-md-start justify-content-center">
          <div *ngFor="let item of items" class="col-auto px-lg-4">
            <a class="text-decoration-none text-dark font-weight-bold mt-3 d-inline-block" [routerLink]="item.path"><h6><b>{{ item.name }}</b></h6></a>
            <ul *ngIf="item.navigation_items.length > 0" class="list-unstyled">
              <li *ngFor="let subitem of item.navigation_items" class="my-1"><a class="text-decoration-none text-dark" [routerLink]="subitem.path">{{ subitem.name }}</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row my-3 justify-content-center py-3">
        <span class="px-0 m-0 text-center" style="font-size: 12px">© Copyright 2020 Zero Waste Stockholm</span>
      </div>
    </footer>
 </div>
</div>
