<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
  <div class="container">   
    <div *ngIf="buttonText" class="m-5 pb-3 text-center">
      <a *ngIf="!buttonLink?.includes('mailto:') && !buttonLink?.includes('https://') && !buttonLink?.includes('#')" 
        [routerLink]="buttonLink" 
        class="btn btn-primary px-4 rounded-0">
        {{ buttonText }}
      </a>
      <a *ngIf="buttonLink?.includes('mailto:') || buttonLink?.includes('https://')" 
        [href]="buttonLink" 
        class="btn btn-primary px-4 rounded-0" 
        rel="noopener nofollow" 
        target="_blank">
        {{ buttonText }}
      </a>
      <a *ngIf="buttonLink?.includes('#')" 
        [href]="buttonLink" 
        class="btn btn-primary px-4 rounded-0">
        {{ buttonText }}
      </a>
    </div>
  </div>
</div>