import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApolloGqlCacheService {

  constructor(private apollo: Apollo) { }

  query(queryStr: string): Observable<any> {

    var subject = new Subject<any>();

    console.log(queryStr);

    let queryGql = gql `${queryStr}`;

    let result = this.apollo.client.readQuery({ query: queryGql });

    if(result != null) {
      console.log(`cached request`);
      subject.next(result);
    }

    this.apollo
      .watchQuery({ query: queryGql })
      .valueChanges.subscribe( (result: any) => { 
        console.log(`not cached request`);
        subject.next(result.data);
      },
      (error: any) => {
        subject.error(null);
      });

      return subject.asObservable();
  }
}