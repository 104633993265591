import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { LoadingService } from '../_services/loading.service';

@Component({
  selector: 'loading-screen-root',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  loading: boolean = true;
  @ViewChild('loadingScreen') loadingScreen!:ElementRef;

  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
    this.disableScrolling();
    this.loadingService.isLoaded.subscribe(value => {
      if(value == true && this.loading == true) {
        let list = this.loadingScreen.nativeElement.classList;
        list.remove('visible');
        list.add('hidden');

        setTimeout(() => { 
          this.loading = false;
          this.enableScrolling();
        }, 1000);
      }
    })
  }

  disableScrolling(){
    var x=window.scrollX;
    var y=window.scrollY;
    window.onscroll=function(){window.scrollTo(x, y);};
  }

  enableScrolling(){
    window.onscroll=function(){};
  }

  ngOnDestroy(): void {
    this.loadingService.isLoaded.unsubscribe();
  }
}
