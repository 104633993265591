<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
  <div class="container px-4 py-5 my-5 my-5">
    <h1 class="pb-4 mb-4 text-center fw-bold">{{ title }}</h1>
    <div class="row row-cols-1 row-cols-md-{{ numberPerRow }} g-4 justify-content-center">
      <div *ngFor="let item of items; let i = index;" class="col animated-card">
        <div class="card h-100 bg-transparent border-0">
          <a [routerLink]="['articles', item.uid]" [attr.aria-label]="item.uid">
            <img *ngIf="item.image != null" class="card-img-top px-3" src="{{ item.image.url }}" alt="{{item.image.alternativeText}}" loading="lazy" style="height: 200px; object-fit: cover;">
          </a>
          <div class="card-body">
            <h5 class="card-title fw-bold mb-4">{{ item.title }}</h5>
            <p class="card-text small">{{ item.introduction }} <a [routerLink]="['articles', item.uid]" aria-label="read-more">{{ item.buttonText }}</a></p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="buttonText" class="row mt-3">
      <div class="col text-center">
        <a [routerLink]="buttonLink" class="btn btn-outline-primary rounded-0 border-2 fw-bolder">{{ buttonText }}</a>
      </div>
    </div>
  </div>
</div>
