import { Component } from '@angular/core';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';
import { ScriptService } from 'src/app/_services/script.service';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'byo-map-root',
  templateUrl: './byo-map.component.html',
  styleUrls: ['./byo-map.component.scss'],
})
export class ByoMapComponent extends BaseSectionComponent {
  name: string = 'Map';
  componentGql!: string;

  title!: string;

  constructor(
    apollo: ApolloGqlCacheService,
    private scriptService: ScriptService
  ) {
    super(apollo);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  assignData() {
    super.assignData();

    this.title = this.sectionData.title;
  }

  ngAfterViewInit() {
    this.scriptService.load('byo');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
