import { Component } from '@angular/core';
import { EventItem } from 'src/app/models/event-item';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'upcoming-events-root',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})

export class UpcomingEventsComponent extends BaseSectionComponent  {

  name: string = "Upcoming Events";
  today!: string;
  collectionGql: string = `events(limit: 5, where: {start_gt: "{today}" }) { event_id, name description start end override_start location street zipcode city country image { formats url name alternativeText } }`;
  componentGql: string = `... on ComponentContentUpcomingEvents { title button_text button_link number_events number_per_row no_events_text background_styling { color } }`;

  title!: string;
  buttonText!: string;
  buttonLink!: string;
  numberEvents!: number;
  numberPerRow!: number;
  noEventsText!: string;

  items: EventItem[] = [];

  constructor(apollo: ApolloGqlCacheService) { 
    super(apollo);

    this.today = new Date().toISOString();
    this.collectionGql = this.collectionGql.replace("{today}", this.today);
    console.log(this.collectionGql);
   }
  
  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;
    this.buttonText = this.sectionData.button_text;
    this.buttonLink = this.sectionData.button_link;
    this.numberEvents = this.sectionData.number_events;
    this.noEventsText = this.sectionData.no_events_text;
    this.numberPerRow = this.sectionData.number_per_row != null ? this.sectionData.number_per_row : 2;

    this.collectionData.events.forEach( (event: { event_id: string, name: string; image:any, description: string; start: string; end: string; override_start: string, location: string; street: string; zipcode: string; city: string; country: string; }) => {
      this.items.push({
        id: event.event_id,
        name: event.name,
        description: event.description,
        shortDescription: event.description?.length > 300 ? event.description.substring(0, 300) + "..." : event.description.substring(0, event.description.length),
        image: this.collectionData.events?.length == 1 ? this.useAvailableImage(event.image, ['L']) : this.useAvailableImage(event.image, ['S', 'M', 'L']),
        start: event.start,
        end: event.end,
        overrideStart: event.override_start,
        location: event.location,
        street: event.street,
        zipcode: event.zipcode,
        city: event.city,
        country: event.country,
        isExtended: false
      })
    })
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
