<ngx-loading-bar color="#007bff" height="5px" ref="http" [includeSpinner]="false"></ngx-loading-bar>

<loading-screen-root></loading-screen-root>

<header class="sticky-top">
  <nav-root></nav-root>
</header>

<main class="d-flex flex-column h-100">
  <router-outlet #outlet="outlet"></router-outlet>
  <footer class="mt-auto footer pt-5 bg-footer">
      <footer-root></footer-root>
  </footer>
</main>