import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'FormattedDateTimePipe'})
export class FormattedDateTimePipe implements PipeTransform {
  transform(value: string): string | null {
  
    if(value == null) {
      return null;
    }

    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let date = new Date(value);

    if(date == null) {
      return null;
    }

    var time = date.toLocaleTimeString();

    let result = `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()} ${time}`;

    return result;
  }
}