import { MarkedOptions, MarkedRenderer } from "ngx-markdown";

export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();
  
    renderer.blockquote = (text: string): string => {
        return '<blockquote class="border-left-3 pl-3 blockquote">' + text + '</blockquote></div>'
    };
  
    renderer.heading = (text: string, level: number): string => {
        const escapedText = text.toLowerCase().replace(/\[(\d*?)\]/g, '$1').replace(/[^\w]+/g, '-').replace(/((-br-)$)|(-$)/, "");
        text = text.replace(/ \[(\d*?)\]/g, '');
        return '<h' + level + ' class="fw-bolder text-left" id="' + escapedText + '">' + text + '</h' + level + '>';
    };

    renderer.image = (href: string, title: string, text: string): string => {
        return '<figure class="figure d-block"><img class="figure-img img-fluid rounded d-block m-auto" src="' + href + '" alt="' + title + '">' + 
        (text != null ? '<figcaption class="figure-caption text-center">' + text + '</figcaption>' : '') +
        '</figure>';
    };

    renderer.table = (header: string, body: string) => {
        return `<table class="table table-striped"><thead>${header}</thead><tbody>${body}</tbody><table>`
    }

    renderer.hr = () => {
        return '<div class="text-center"><span class="dot m-3"></span><span class="dot m-3"></span><span class="dot m-3"></span></div>';
    };

    renderer.link = (href: string, title: string, text: string): string => {
        if (text.includes('→')) {
            if(href.includes('https://') || href.includes('http://')) {
                return '<a class="pointer link-warning" href="' + href  + '" target="_blank" rel="noopener nofollow">' + text + '</a>'
            }
            else {
                return '<a class="pointer link-warning" href="' + href  + '">' + text + '</a>'
            }
        }
        else if(href.includes('/') || href.includes('@')) {
            if(href.includes('https://') || href.includes('http://')) {
                return '<a href="' + href + '" target="_blank" rel="noopener nofollow">' + text + '</a>';
            }
            else {
                return '<a href="' + href + '" >' + text + '</a>';
            }
        }
        return '<a class="pointer link-primary" href="' + location.pathname + '#' + href  + '">' + text + '</a>';
    }
    
    return {
      renderer: renderer,
      gfm: true,
      breaks: true,
      pedantic: false,
      smartLists: true,
      smartypants: false,
    };
  }
