export interface ScriptItem {
  loaded: boolean;
  name: string;
  src: string;
}
export const ScriptStore: ScriptItem[] = [
  { name: 'byo', src: '/assets/byo/scripts/leaflet.js', loaded: false },
  {
    name: 'cityguide',
    src: '/assets/cityguide/scripts/leaflet.js',
    loaded: false,
  },
];
