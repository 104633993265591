import { Component, ViewChild } from '@angular/core';
import { FormItem } from 'src/app/models/form-item';
import { BaseSectionComponent } from '../../base/base-section/base-section.component';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { FormControlService } from 'src/app/_services/field-control.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'form-root',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', [ 
        transition(':enter', [ style({ height: 0, opacity: 0 }), animate('1.0s 3.0s ease-out', style({ height: '*', opacity: 1 })) ] )
      ]
    ),
    trigger(
      'expandCollapseAnimation', [ 
        transition(':enter', [ style({ height: 0 }), animate('1.0s 1.5s ease-out', style({ height: '*' })) ] ),
        transition(':leave', [ style({ height: '*' }), animate('1.0s 0.0s ease-in', style({ height: 0 })) ] )
      ]
    )
  ]
})

export class FormComponent extends BaseSectionComponent {

  @ViewChild('captchaRef') captchaRef: any;
  form!: FormGroup;
  
  name: string = "Form";
  componentGql: string = `... on ComponentContentForm { title subtitle description submit_text success_title success_description form_type side contrast fields { input_name display_name type required options default_value } }`;

  title!: string;
  subtitle!: string;
  description!: string;
  submitText!: string;
  successTitle!: string;
  successDescription!: string;
  formType!: string;
  side!: string;
  contrast!: string;
  fields: FormItem[] = [];

  blocks: any[] = [];
  successBlocks: any[] = [];

  loading = false;
  success: boolean = false;
  errorMessage!: string;
  recaptchaSiteKey: string;
  captchaResponse!: string;

  swishQrCode!: string; 

  constructor(
    apollo: ApolloGqlCacheService, 
    private http: HttpClient,
    private formControlService: FormControlService,
    private languageService: LanguageService) { 
    super(apollo);
    this.recaptchaSiteKey = environment.googleRecaptchaSiteKey; 
  }

  ngOnInit() { super.ngOnInit() }

  assignData() {
    
    super.assignData();

    this.title = this.sectionData.title;
    this.subtitle = this.sectionData.subtitle;
    this.description = this.sectionData.description;
    this.submitText = this.sectionData.submit_text;
    this.successTitle = this.sectionData.success_title;
    this.successDescription = this.sectionData.success_description;
    this.formType = this.sectionData.form_type;
    this.background = this.sectionData.background;
    this.side = this.sectionData.side;
    this.contrast = this.sectionData.contrast;
    
    this.blocks = this.description?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm);
    this.blocks = this.blocks?.filter(x => { return x });
    
    this.successBlocks = this.successDescription?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm);
    this.successBlocks = this.successBlocks?.filter(x => { return x });

    if(!this.sectionData.fields) {
      return;
    }

    this.sectionData.fields.forEach((field: any) => {
      this.fields.push( {
        key: field.input_name,
        required: field.required,
        display: field.required ? `${field.display_name}*`: field.display_name,
        type: field.type,
        options: field.options?.split(';').map((option: string, i: number) => {
          return { item: String(i), text: option }
        }),
        selected: [],
        default: field.default_value
      } );
    });

    console.log(this.fields);

    this.form = this.formControlService.toFormGroup(this.fields);
  }

  onSubmit(): void {

    let payload = JSON.stringify(this.form.getRawValue(), null, 4);
    let contactEmail = this.form?.getRawValue()?.contactEmail;

    console.log(payload);

    this.success = false;
    this.errorMessage = '';
    this.loading = true;

    console.log(this.captchaResponse);

    this.http.post(`${environment.serverEndpoint}/contact/message`, { 
      title: this.title,
      type: this.formType,
      lang: this.languageService.current.iso,
      contactEmail: contactEmail,
      payload: payload,
      response: this.captchaResponse
    })
    .subscribe(
      (data: any) => {

        console.log(data);

        this.onFormFeedback();
      },
      (error: any) => {

        this.loading = false;
        this.errorMessage = `Backend server returned an error.`;
      }
    );
  }

  public resolved(captchaResponse: string): void {
    this.captchaResponse = captchaResponse;

    if(this.captchaResponse != null){
      this.onSubmit();
      this.captchaRef.reset();
    }
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    this.loading = false;
    this.errorMessage = `Backend server returned an error.`;
  }

  public onFormFeedback() {

    let email = this.form.getRawValue().contactEmail;
    let amount = this.form.getRawValue().amount;

    switch(this.formType) {
          
      case "donate":
        this.http.post(`${environment.serverEndpoint}/swish/donate`, { amount: amount, message: `ZWS Donate: ${email}` })
        .subscribe(
          (data: any) => {
            console.log(data.qrCode);
            this.swishQrCode = 'data:image/jpg;base64,' + data.qrCode;
            this.loading = false;
            this.success = true;
          },
          (error: any) => {

            this.loading = false;
            this.errorMessage = `Backend server returned an error (${error.status})`;
          }
        );
        break;
      
      case "membership":
        this.http.post(`${environment.serverEndpoint}/swish/membership`, { message: `ZWS Membership: ${email}` })
        .subscribe(
          (data: any) => {
            console.log(data.qrCode);
            this.swishQrCode = 'data:image/jpg;base64,' + data.qrCode;
            this.loading = false;
            this.success = true;
          },
          (error: any) => {

            this.loading = false;
            this.errorMessage = `Backend server returned an error (${error.status})`;
          }
        );
        break;
      
      case "newsletter":
        this.http.post(`${environment.serverEndpoint}/newsletter/register`, { email: email })
        .subscribe(
          (data: any) => {
            console.log(data.qrCode);
            this.loading = false;
            this.success = true;
          },
          (error: any) => {

            this.loading = false;
            this.errorMessage = `Backend server returned an error (${error.status})`;
          }
        );
        break;

      default:
        this.loading = false;
        this.success = true;
        break;
    }
  }

  scrollToPaymentDetails(id: string) {
    let el = document.getElementById(id);
    el?.scrollIntoView();
  }

  ngOnDestroy() { super.ngOnDestroy() }
}
