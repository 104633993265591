
<div class="h-100" [hidden]="!isLoaded">
    <div *ngFor="let item of sections" sxpAnimate [ngClass]="{'delayed': item.index != 0}" [style]="item.type == 'ComponentContentBetweenSectionImage' ? 'position: absolute; z-index: 100' : ''">
        <div [ngSwitch]="item.type">
            <div *ngSwitchCase="'ComponentContentIntro'">
                <intro-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [collectionType]="collectionType"
                    [sectionCount]="sections.length"
                    (loadedEvent)="sectionLoaded($event)">
                </intro-root>
            </div>
            <div *ngSwitchCase="'ComponentContentSplit'">
                <split-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </split-root>
            </div>
            <div *ngSwitchCase="'ComponentContentContinueFurther'">
                <continue-further-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </continue-further-root>
            </div>
            <div *ngSwitchCase="'ComponentContentMultipleFeatured'">
                <multiple-featured-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </multiple-featured-root>
            </div>
            <div *ngSwitchCase="'ComponentContentMultipleColoredBlocks'">
                <multiple-colored-blocks-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </multiple-colored-blocks-root>
            </div>
            <div *ngSwitchCase="'ComponentContentUpcomingEvents'">
                <upcoming-events-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </upcoming-events-root>
            </div>
            <div *ngSwitchCase="'ComponentContentLatestArticles'">
                <latest-articles-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </latest-articles-root>
            </div>
            <div *ngSwitchCase="'ComponentContentInstagramGallery'">
                <instagram-gallery-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </instagram-gallery-root>
            </div>
            <div *ngSwitchCase="'ComponentContentEvents'">
                <events-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </events-root>
            </div>
            <div *ngSwitchCase="'ComponentContentByoMap'">
                <byo-map-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </byo-map-root>
            </div>
            <div *ngSwitchCase="'ComponentContentCityGuideMap'">
                <cityguide-map-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </cityguide-map-root>
            </div>
            <div *ngSwitchCase="'ComponentContentForm'">
                <form-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </form-root>
            </div>
            <div *ngSwitchCase="'ComponentContentButton'">
                <button-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </button-root>
            </div>
            <div *ngSwitchCase="'ComponentContentLatestNews'">
                <latest-news-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </latest-news-root>
            </div>
            <div *ngSwitchCase="'ComponentContentPartners'">
                <partners-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </partners-root>
            </div>
            <div *ngSwitchCase="'ComponentContentMarkdownBlock'">
                <markdown-block-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </markdown-block-root>
            </div>
            <div *ngSwitchCase="'ComponentContentDownloads'">
                <downloads-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </downloads-root>
            </div>
            <div *ngSwitchCase="'ComponentContentArticles'">
                <articles-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </articles-root>
            </div>
            <div *ngSwitchCase="'ComponentContentBetweenSectionImage'">
                <between-section-image-root 
                    [parentGql]="parentGql" 
                    [locale]="locale" 
                    [index]="item.index" 
                    [sectionCount]="sections.length"
                    [collectionType]="collectionType" 
                    (loadedEvent)="sectionLoaded($event)">
                </between-section-image-root>
            </div>
        </div>
    </div>
</div>