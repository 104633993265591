import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LanguageService } from "../_services/language.service";

@Injectable({ providedIn: 'root' })
export class DataResolver implements Resolve<boolean> {

  storedRoute!: string;

  constructor(
    private router: Router) {
    
    this.router.events.subscribe((event: any) => {
      if(event instanceof NavigationEnd) {
        if(event.url != "/" && this.storedRoute != event.url) {
          this.storedRoute = event.url;
          this.redirectTo(event.url);
        }
      }
    });
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigate([uri]));
  }
 
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any>|any {
    return true;
  }
}