import { Component } from '@angular/core';
import { NewsItem } from 'src/app/models/news-item';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'latest-news-root',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.scss']
})

export class LatestNewsComponent extends BaseSectionComponent  {

  name: string = "Latest News";
  today!: string;
  collectionGql: string = `newsItems(locale: "{locale}", limit: 5, sort: "date:asc") { title description date uid button_link button_text image { formats url name alternativeText } }`;
  componentGql: string = `... on ComponentContentLatestNews { title button_text button_link number_news number_per_row background_styling { color } }`;

  title!: string;
  buttonText!: string;
  buttonLink!: string;
  numberNews!: number;
  numberPerRow!: number;

  items: NewsItem[] = [];

  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;
    this.buttonText = this.sectionData.button_text;
    this.buttonLink = this.sectionData.button_link;
    this.numberNews = this.sectionData.number_news != null ? this.sectionData.number_news : 10;
    this.numberPerRow = this.sectionData.number_per_row != null ? this.sectionData.number_per_row : 2;

    this.collectionData.newsItems.forEach( (news: { title: string; image:any, description: string; date: string; uid: string; button_link: string; button_text: string }) => {

      if(this.items.length < this.numberNews){
        this.items.push({
          title: news.title,
          description: news.description?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm).filter(x => { return x }),
          image: this.collectionData.newsItems?.length == 1 ? this.useAvailableImage(news.image, ['L']) : this.useAvailableImage(news.image, ['S', 'M', 'L']),
          date: news.date,
          uid: news.uid,
          buttonText: news.button_text,
          buttonLink: news.button_link,
        })
      }
    })
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
