import { Component } from '@angular/core';
import { PartnerItem } from 'src/app/models/partner-item';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'partners-root',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})

export class PartnersComponent extends BaseSectionComponent  {

  name: string = "Partners";
  today!: string;
  collectionGql: string = `partners(locale: "{locale}", sort: "order:asc") { name description uid button_link button_text order image { formats url name alternativeText } }`;
  componentGql: string = `... on ComponentContentPartners { title button_text button_link number_partners number_per_row background_styling { color } }`;

  title!: string;
  buttonText!: string;
  buttonLink!: string;
  numberPartners!: number;
  numberPerRow!: number;

  items: PartnerItem[] = [];
  
  ngOnInit() { super.ngOnInit(); }

  assignData() {
    
    super.assignData();

    this.title = this.sectionData.title;
    this.buttonText = this.sectionData.button_text;
    this.buttonLink = this.sectionData.button_link;
    this.numberPartners = this.sectionData.number_partners;
    this.numberPerRow = this.sectionData.number_per_row != null ? this.sectionData.number_per_row : 2;
    
    this.collectionData.partners.forEach( (partner: { name: string; image:any, description: string; uid: string; button_link: string; button_text: string }) => {
      this.items.push({
        name: partner.name,
        description: partner.description?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm).filter(x => { return x }),
        image: this.collectionData.partners?.length == 1 ? this.useAvailableImage(partner.image, ['L']) : this.useAvailableImage(partner.image, ['S', 'M', 'L']),
        uid: partner.uid,
        buttonText: partner.button_text,
        buttonLink: partner.button_link,
      })
    })

    this.collectionData.partners.order
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
