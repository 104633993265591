import { Component } from '@angular/core';
import { ArticleItem } from 'src/app/models/article-item';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'articles-root',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})

export class ArticlesComponent extends BaseSectionComponent  {

  name: string = "Articles";
  baseCollectionGql: string = `articles(locale: "{locale}", limit: {limit}, sort: "published_date:{sorting}", start: {start}) { title introduction author button_text uid image { formats url name alternativeText } }`;
  collectionGql: string = ``;
  componentGql: string = `... on ComponentContentArticles { featured_title button_text number_articles number_per_row sorting background_styling { color } featured_articles { title introduction author image { formats } } }`;

  featuredTitle!: string;
  sorting!: any;
  numberArticles!: number;
  numberPerRow!: number;
  buttonText!: string;
  
  isLoading: boolean = false;
  isLastArticle: boolean = false;

  items: ArticleItem[] = [];

  currentSorting: string = "desc";

  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  constructor(apollo: ApolloGqlCacheService) { 
    super(apollo);
    this.collectionGql = this.baseCollectionGql.replace("{start}",  `${this.items.length}`).replace('{limit}', '6').replace('{sorting}', 'desc');
    console.log(this.collectionGql); }
  
  ngOnInit() { 
    super.ngOnInit();
    this.baseCollectionGql = this.baseCollectionGql.replace('{locale}', this.locale);
  }

  assignData() {

    super.assignData();

    this.featuredTitle = this.sectionData.featured_title;
    this.sorting = this.sectionData.sorting;
    this.numberArticles = this.sectionData.number_articles;
    this.numberPerRow = this.sectionData.number_per_row != null ? this.sectionData.number_per_row : 2;
    this.buttonText = this.sectionData.button_text;

    if(this.collectionData.articles == null || this.collectionData.articles.length < this.numberArticles) {
      this.isLastArticle = true;
    }

    this.collectionData.articles.forEach( (article: { title: string; introduction: string, author: string; button_text: string; uid: string; image: any; }) => {

      if(this.items.length <= this.numberArticles) {
        this.items.push({
          title: article.title,
          introduction: article.introduction?.length > 300 ? `${article.introduction.substring(0,300)}...` : article.introduction,
          author: article.author,
          buttonText: article.button_text,
          image: this.useAvailableImage(article.image, ['S', 'M', 'L']),
          uid: article.uid
        })
      }
    })
  }

  moreArticles() {

    this.isLoading = true;
    
    this.collectionGql = this.baseCollectionGql
      .replace('{start}', `${this.items.length}`)
      .replace('{limit}', `${this.items.length != 0 ? this.numberPerRow : 4}`)
      .replace('{sorting}', `${this.currentSorting}`);

    console.log(this.collectionGql);

    this.apollo.query(`{${this.collectionGql}}`).subscribe((data: any) => {
      
      console.log(data);

      if(data.articles == null || data.articles.length < `${this.numberPerRow}`) {
        this.isLastArticle = true;
      }

      data.articles.forEach( (article: { title: string; introduction: string, author: string; button_text: string; uid: string; image: any; }) => {
        this.items.push({
          title: article.title,
          introduction: article.introduction.length > 300 ? `${article.introduction.substring(0,300)}...` : article.introduction,
          author: article.author,
          buttonText: article.button_text,
          image: this.useAvailableImage(article.image, ['S', 'M', 'L']),
          uid: article.uid
        })
      })
      
      this.isLoading = false;
    })
  }

  switchSorting() {
    switch(this.currentSorting){
      case "asc":
        this.currentSorting = "desc";
        break;
      case "desc":
        this.currentSorting = "asc";
        break;
    }
    this.items = [];
    this.moreArticles();
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
