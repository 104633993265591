import { Component, ElementRef, ViewChild } from '@angular/core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { EventItem } from 'src/app/models/event-item';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'events-root',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})

export class EventsComponent extends BaseSectionComponent  {

  name: string = "Events";
  today!: string;
  baseCollectionGql: string = `events(limit: 6, sort: "start:desc", where: { start_lt: "{start}"}) { event_id name description start end override_start location street zipcode city country image { formats url name alternativeText } }`;
  collectionGql: string = '';
  componentGql: string = `... on ComponentContentEvents { title button_text months }`;
  timeSpanGql: string = `{ endQuery: events (sort: "start:desc", limit: 1) { start } startQuery: events (sort: "start:asc", limit: 1) { start } }` ;

  title!: string;
  buttonText!: string;
  months!: string;

  isLoading: boolean = false;
  isLastEvent: boolean = false;
  isToggleOpen: boolean = false;
  faFacebook = faFacebook;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  rangeYear: number[] = [];
  currentYear!: number ;

  groups: { name: string, events: EventItem[] }[] = []
  
  @ViewChild('dropdownYear') dropdownYear!:ElementRef;

  constructor(apollo: ApolloGqlCacheService) { 
    super(apollo);
    this.collectionGql = this.baseCollectionGql.replace("{start}", "2100-01-01");
    console.log(this.collectionGql);
   }
  
  ngOnInit() { 
    super.ngOnInit()
    
    this.apollo.query(this.timeSpanGql).subscribe(
      (data: any) => { 
        try {
          this.currentYear = new Date(data.endQuery[0].start).getFullYear();
          let start = new Date(data.startQuery[0].start).getFullYear();
          this.rangeYear = this.getRangeYear(this.currentYear, start, -1);
        }
        catch(err: any) {
          console.error(err.message);
        }
      }
    )
  }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;
    this.buttonText = this.sectionData.button_text;
    this.months = this.sectionData.months;

    this.processsEvents(this.collectionData.events);
  }

  processsEvents(events: any) {

    this.isLastEvent = events.length < 6;
    
    let items: { id: string; name: string; description: string; shortDescription: string; image: any; start: string; end: string; overrideStart: string; location: string; street: string; zipcode: string; city: string; country: string; isExtended: boolean; }[] = [];

    events.forEach( (event: { event_id: string, name: string; image:any, description: string; start: string; end: string; override_start: string, location: string; street: string; zipcode: string; city: string; country: string; }) => {
      items.push({
        id: event.event_id,
        name: event.name,
        description: event.description,
        shortDescription: event.description?.length > 250 ? event.description?.substring(0, 250) + "..." : event.description?.substring(0, event.description.length),
        image: this.useAvailableImage(event.image, ['S']),
        start: event.start,
        end: event.end,
        overrideStart: event.override_start,
        location: event.location,
        street: event.street,
        zipcode: event.zipcode,
        city: event.city,
        country: event.country,
        isExtended: false,
      })
    })

    let groups = items.reduce((groups: { [category: string]: EventItem[] }, event) => {
      
      const date = new Date(event.start);
      const month = this.months[date.getMonth()];
      const year = date.getFullYear();

      const category = `${month} ${year}`;

      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(event);
      return groups;
    }, {});

    for (let key in groups) {
      this.groups.push({name: key, events: groups[key]});
    }
  }
  
  extendEvent(item: EventItem) {
    item.isExtended = !item.isExtended;
  }

  moreEvents(date: string | null = null) {

    this.isLoading = true;

    let lastDate = date == null ? this.getCurrentOldestEvent() : date;
    
    this.collectionGql = this.baseCollectionGql.replace("{start}", `${lastDate != null ? lastDate : "2000-01-01"}`);
    console.log(this.collectionGql);

    this.apollo.query(`{${this.collectionGql}}`).subscribe( (data: any) => {
      this.processsEvents(data.events);
      this.isLoading = false;
    });
  }

  getCurrentOldestEvent() {
    return this.groups.slice(-1)[0]?.events.slice(-1)[0]?.start;
  }

  getRangeYear(start: number, stop: number, step: number) {
    return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  } 
  
  switchSorting(event: any) {
    this.currentYear = parseInt(event?.target?.innerText);
    this.toggleDropdown();
    this.groups = [];
    this.moreEvents(`${this.currentYear}-12-31`);
  }

  toggleDropdown() {
    this.isToggleOpen = !this.isToggleOpen;

    if(this.isToggleOpen) {
      this.dropdownYear.nativeElement.classList.add('show');
      this.dropdownYear.nativeElement.classList.remove('hide');
    }
    else {
      this.dropdownYear.nativeElement.classList.add('hide');
      this.dropdownYear.nativeElement.classList.remove('show');
    }
  }

  ngOnDestroy() { super.ngOnDestroy() }
}
