import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})

export class LoadingService {

    public isLoaded: BehaviorSubject<boolean>;
    
    constructor() {
        this.isLoaded = new BehaviorSubject<boolean>(false);
    }

    getValue(): Observable<boolean> {
        return this.isLoaded.asObservable();
    }

    setValue(newValue: any): void {
        this.isLoaded.next(newValue);
    }
}