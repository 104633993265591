import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

const COOKIE_KEY = 'cookie-allow';
const LANGUAGE_KEY = 'language-preferred';

@Injectable({
  providedIn: 'root'
})

export class CookieStorageService { 

  constructor(private cookieService: CookieService) { }

  public getCookieAllowance(): any {
    let cookie = this.cookieService.get(COOKIE_KEY);
    return cookie ? cookie : window.sessionStorage.getItem(COOKIE_KEY);
  }

  public setCookieAllowance(value: any): void {
    if(value) {
      this.cookieService.set(COOKIE_KEY, value, undefined, '/', undefined, true, 'Lax');
    }
    else {
      this.cookieService.deleteAll();
    }
    window.sessionStorage.removeItem(COOKIE_KEY);
    window.sessionStorage.setItem(COOKIE_KEY, value);
  }

  public getLanguage(): any {
    let cookie = this.cookieService.get(LANGUAGE_KEY);
    return cookie != null && cookie != '' ? cookie : window.sessionStorage.getItem(LANGUAGE_KEY);
  }

  public setLanguage(value: any): void {
    window.sessionStorage.removeItem(LANGUAGE_KEY);
    window.sessionStorage.setItem(LANGUAGE_KEY, value);
    if(this.getCookieAllowance() == 'true') {
      console.log(value);
      this.cookieService.set(LANGUAGE_KEY, value, undefined, '/', undefined, true, 'Lax');
    }
  }
}