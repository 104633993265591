
<div class="container__banner py-5" [style]="'dark' | BackgroundPipe">
  <h2 class="d-block text-center mt-3 mb-1" style="font-weight: bold;">{{ title }}</h2>
  <div class="text-center">
    <div class="dropdown d-inline-block">
      <a class="btn btn-white small" role="button" aria-expanded="false" (click)="toggleDropdown()">
        {{currentYear}}
        <fa-icon class="ms-2" [icon]="isToggleOpen ? faCaretUp : faCaretDown" ></fa-icon>
      </a>
      <ul class="dropdown-menu border-1 bg-transparent position-absolute py-0" style="transform: translateX(-50%); left: 50%;" #dropdownYear>
        <li *ngFor="let date of rangeYear">
          <div class="dropdown-item small" style="cursor: pointer;" (click)="switchSorting($event)">{{date}}</div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngFor="let group of groups; let i = index" class="container__banner" [style]="'dark' | BackgroundPipe">
  <div class="container px-4">
    <div *ngFor="let item of group.events" class="row justify-content-md-center px-2 animated-card">
      <div class="col-sm-4">
        <img *ngIf="item.image != null" class="card-img-top py-3 pointer" src="{{ item.image.url }}" alt="Event picture - {{item.image.name}}" loading="lazy" style="max-height: 250px; object-fit: cover;" (click)="extendEvent(item)">
      </div>
      <div class="col-sm-8">
        <div class="card-body">
          <h4 class="card-title fw-bold mb-3">{{ item.name }}</h4>
          <p class="mb-0 small">{{ item.start | FormattedDateTimePipe }}</p>
          <p *ngIf="!item.isExtended" class="card-text small">{{ item.shortDescription }} <a role="button" tabindex class="read-more" (click)="extendEvent(item)">Read more</a></p>
          <p *ngIf="item.isExtended" class="card-text small">{{ item.description }} <a role="button" tabindex="0" class="read-more" (click)="extendEvent(item)">Read less</a></p>
          <div class="d-block my-2 text-start">
            <div class="px-0">
              <a class="me-3 my-1 d-inline-block" href="https://www.facebook.com/events/{{item.id}}" rel="noopener"><img src="/assets/icons/facebook.svg" class="fa__external" alt="Facebook"/></a>
              <a class="me-3 my-1 d-inline-block" href="https://www.meetup.com/Zero-Waste-Stockholm/" rel="noopener"><img src="/assets/icons/meetup.svg" class="fa__external" alt="Meetup"/></a>
              <a class="me-3 my-1 d-inline-block" href="https://linktr.ee/zerowastestockholm" rel="noopener"><img src="/assets/icons/linktree.svg" class="fa__external" alt="Linktree"/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(i == groups.length - 1) && !isLastEvent" class="row mt-5">
    <div class="col text-center">
      <button *ngIf="!isLoading" type="button" class="btn btn-outline-primary rounded-0 border-2 fw-bolder mb-3" (click)="moreEvents()" [disabled]="isLoading">{{ buttonText }}</button>
      <span *ngIf="isLoading" class="spinner-border border-5 text-primary fw-bolder mb-3" role="status" style="width: 3rem; height: 3rem;"></span>
    </div>
  </div>
</div>
