import { Component } from '@angular/core';
import { ArticleItem } from 'src/app/models/article-item';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'latest-articles-root',
  templateUrl: './latest-articles.component.html',
  styleUrls: ['./latest-articles.component.scss']
})

export class LatestArticlesComponent extends BaseSectionComponent  {

  name: string = "Latest Articles";
  today!: string;
  collectionGql: string = `articles(locale: "{locale}", limit: 5, sort: "published_date:desc") { title introduction button_text uid image { formats url name alternativeText } }`;
  componentGql: string = `... on ComponentContentLatestArticles { title button_text button_link number_articles number_per_row background_styling { color } }`;

  title!: string;
  buttonText!: string;
  buttonLink!: string;
  numberArticles!: number;
  numberPerRow!: number;

  items: ArticleItem[] = [];
  
  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;
    this.buttonText = this.sectionData.button_text;
    this.buttonLink = this.sectionData.button_link;
    this.numberArticles = this.sectionData.number_articles != null ? this.sectionData.number_articles : 10;
    this.numberPerRow = this.sectionData.number_per_row != null ? this.sectionData.number_per_row : 2;

    this.collectionData.articles.forEach( (article: { title: string; image:any, introduction: string; author: string; button_text: string; content: string; uid: string }) => {
      if(this.items.length < this.numberArticles) {
        this.items.push({
          title: article.title,
          introduction: article.introduction?.length > 300 ? `${article.introduction.substring(0,300)}...` : article.introduction,
          image: this.collectionData.articles?.length == 1 ? this.useAvailableImage(article.image, ['L']) : this.useAvailableImage(article.image, ['S', 'M', 'L']),
          author: article.author,
          buttonText: article.button_text,
          uid: article.uid
        })
      }
    })
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
