import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormItem } from 'src/app/models/form-item';

@Component({
  selector: 'form-item-root',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.scss']
})

export class FormItemComponent {

  @Input() field!: FormItem;
  @Input() form!: FormGroup;

  dropdownSettings: any;

  constructor() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item',
      textField: 'text',
      maxHeight: 110,
      enableCheckAll : false
    };
  }

  get isValid() {
    return this.form.controls[this.field.key].valid;
  }

  get isEmpty() {
    var str = this.form.controls[this.field.key].value;
    return (!str || str.length === 0 );
  }

  checkboxValueChanged(values:any):void {
    
    if(values.currentTarget.required) {
      if(values.currentTarget.checked) {
        this.form.controls[values.currentTarget.id].setErrors(null);
      }
      else {
        this.form.controls[values.currentTarget.id].setErrors({ mustAgree: values.currentTarget.checked});
      }
    }
  }
}
