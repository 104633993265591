import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormItem } from '../models/form-item';

@Injectable({
  providedIn: 'root'
})

export class FormControlService {

  constructor() { }

  toFormGroup(fields: FormItem[] ) {
    
    const group: any = {};

    fields.forEach(field => {

      switch(field.type) {
        case 'number':
          group[field.key] = field.required ? new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]) : new FormControl('', Validators.pattern("^[0-9]*$"));
          break;
        case 'email':
          group[field.key] = field.required ? new FormControl('', [Validators.required, Validators.email]) : new FormControl('', Validators.email);
          break;
        case 'phone':
          group[field.key] = field.required ? new FormControl('', [Validators.required, Validators.pattern("^[0-9 +]*$")]) : new FormControl('', Validators.pattern("^[0-9 +]*$"));
          break;
        case 'checkbox':
          group[field.key] = field.required ? new FormControl(false, Validators.requiredTrue)  : new FormControl(false);
          break;
        default:
          group[field.key] = field.required ? new FormControl('', Validators.required) : new FormControl('');
          break;
      }
    });

    var form = new FormGroup(group);

    fields.filter(x => x.type == 'checkbox' && x.required).forEach(x => {
      form.setErrors({ mustAgree: false});
      console.log(form.controls[x.key].errors)
    });
    
    console.log(Object.entries(form.controls).map(([key, {errors}]) => [key, errors]))

    return new FormGroup(group);
  }
}