<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
  <div class="container px-4 py-5 my-5 my-5" >
    <h1 class="pb-4 mb-4 text-center fw-bold">{{ title }}</h1>
    <div class="row row-cols-1 row-cols-md-{{itemsPerRow}} g-4 justify-content-center">
      <div *ngFor="let item of items; let i = index;" class="col animated-card">
        <div class="card h-100 bg-transparent border-0">
          <a *ngIf="item.image != null && item.buttonLink.includes('https://')" href="{{ item.buttonLink }}" attr.aria-label="link" target="_blank" rel="noopener nofollow">
            <img class="card-img-top px-3" [ngClass]="item.imageFit" src="{{ item.image.url }}" alt="{{item.image.alternativeText}}" loading="lazy" style="height: 200px;">
          </a>
          <a *ngIf="item.image != null && !item.buttonLink.includes('https://')" [routerLink]="item.buttonLink" attr.aria-label="Link to featured element" >
            <img class="card-img-top px-3" [ngClass]="item.imageFit" src="{{ item.image.url }}" alt="{{item.image.alternativeText}}" loading="lazy" style="height: 200px;">
          </a>
          <div class="card-body">
            <h5 class="card-title fw-bold mb-4">{{ item.title }}</h5>
            <div *ngFor="let block of item.description">
              <div class="card-text markdown small" markdown emoji [data]="block"></div>
            </div>
          </div>
          <div *ngIf="item.buttonText && item.buttonLink" class="card-footer bg-transparent border-0">
            <a *ngIf="!item.buttonLink?.includes('mailto:') && !item.buttonLink?.includes('https://')" [routerLink]="item.buttonLink" class="btn btn-outline-primary rounded-0 border-2 fw-bolder">{{ item.buttonText }}</a>
            <a *ngIf="item.buttonLink?.includes('mailto:') || item.buttonLink?.includes('https://')"  [href]="item.buttonLink" class="btn btn-outline-primary rounded-0 border-2 fw-bolder" rel="noopener nofollow" target="_blank">{{ item.buttonText }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>