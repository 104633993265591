import { Component } from '@angular/core';
import { DownloadItem } from 'src/app/models/download-item';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'downloads-root',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})

export class DownloadsComponent extends BaseSectionComponent  {

  name: string = "Downloads";
  baseCollectionGql: string = `downloads(locale: "{locale}", limit: {limit}, start: {start}) { title description button_link button_text image { formats name url alternativeText } download { url name alternativeText } }`;
  collectionGql: string = '';
  componentGql: string = `... on ComponentContentDownloads { button_text number_downloads number_per_row background_styling { color } }`;

  numberDownloads!: number;
  numberPerRow!: number;
  buttonText!: string;
  
  isLoading: boolean = false;
  isLastDownload: boolean = false;

  items: DownloadItem[] = [];

  constructor(apollo: ApolloGqlCacheService) { 
    super(apollo);
    this.collectionGql = this.baseCollectionGql.replace("{start}",  `${this.items.length}`).replace('{limit}', '6');
    console.log(this.collectionGql);
  }
  
  ngOnInit() {
     super.ngOnInit();
    this.baseCollectionGql = this.baseCollectionGql.replace('{locale}', this.locale);
  }

  assignData() {

    super.assignData();

    this.numberDownloads = this.sectionData.number_downloads;
    this.numberPerRow = this.sectionData.number_per_row != null ? this.sectionData.number_per_row : 2;
    this.buttonText = this.sectionData.button_text;

    if(this.collectionData.downloads == null || this.collectionData.downloads.length < this.numberDownloads) {
      this.isLastDownload = true;
    }

    this.collectionData.downloads.forEach( (download: { title: string; image:any, download:any, description: string; uid: string; button_link: string; button_text: string }) => {
      if(this.items.length < this.numberDownloads) {
        this.items.push({
          title: download.title,
          description: download.description?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm).filter(x => { return x }),
          image: this.collectionData.downloads?.length == 1 ? this.useAvailableImage(download.image, ['L']) : this.useAvailableImage(download.image, ['S', 'M', 'L']),
          download: download.download,
          uid: download.uid,
          buttonText: download.button_text,
          buttonLink: download.button_link
        })
      }
    })
  }

  moreDownloads() {

    this.isLoading = true;
    
    this.collectionGql = this.baseCollectionGql.replace("{start}", `${this.items.length}`).replace('{limit}', `${this.numberPerRow}`);
    
    this.apollo.query(`{${this.collectionGql}}`).subscribe( (data: any) => {

      console.log(data);

      if(data.downloads == null || data.downloads.length < `${this.numberPerRow}`) {
        this.isLastDownload = true;
      }

      data.downloads.forEach( (download: { title: string; image:any, download:any, description: string; uid: string; button_link: string; button_text: string }) => {
        this.items.push({
          title: download.title,
          description: download.description.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm).filter(x => { return x }),
          image: download.image,
          download: download.download,
          uid: download.uid,
          buttonText: download.button_text,
          buttonLink: download.button_link
        })
      })
      
      this.isLoading = false;
    })
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
