import { Component } from '@angular/core';
import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'continue-further-root',
  templateUrl: './continue-further.component.html',
  styleUrls: ['./continue-further.component.scss']
})

export class ContinueFurtherComponent extends BaseSectionComponent {

  name: string = "ContinueFurther";
  componentGql: string = `... on ComponentContentContinueFurther { title description external_links { name link } image { formats url name alternativeText } image_styling { side fit } background_styling { color } }`;

  title!: string;
  description!: string;
  side!: string;
  fit!: string;
  image: any = {};
  externalLinks: any = [];

  blocks: any[] = [];
  
  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;
    this.description = this.sectionData.description;
    this.image = this.useAvailableImage(this.sectionData.image, ['M','L']),
    this.side = this.sectionData.image_styling?.side;
    this.fit = this.sectionData.image_styling?.fit;
    this.externalLinks = this.sectionData.external_links;

    this.blocks = this.description?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm);
    this.blocks = this.blocks?.filter(x => { return x });
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
