<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
    <div class="container">
        <div *ngIf="image != null" class="row justify-content-md-center py-5 px-2">
            <div class="col-md-6" [ngClass]="{'order-md-2': side === 'right'}">
                <img class="img-fluid border-0" [ngClass]="{'img__contained': fit === 'contain', 'img__cover': fit === 'cover'}" src="{{ image.url }}" alt="{{ image.alternativeText }}" loading="lazy">
            </div>
            <div class="col-md-6 order-md-1 d-flex flex-column">
                <div *ngIf="title" class="row mx-3 mb-2">
                    <h2 class="mt-md-0 mt-3" style="font-weight: bold;">{{ title }}</h2>
                </div>
                <div *ngIf="blocks && blocks.length > 0" class="row mx-3">
                    <div *ngFor="let block of blocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
                <div class="mx-4 my-3 pb-3">
                    <div *ngFor="let item of externalLinks">
                        <div [ngSwitch]="item.name">
                            <div *ngSwitchCase="'Facebook'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/facebook.svg" alt="Facebook"/>Facebook</a>
                            </div>
                            <div *ngSwitchCase="'LinkedIn'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/linkedin.svg" alt="LinkedIn"/>LinkedIn</a>
                            </div>
                            <div *ngSwitchCase="'Meetup'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/meetup.svg" alt="Meetup"/>Meetup</a>
                            </div>
                            <div *ngSwitchCase="'LinkTree'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/linktree.svg" alt="LinkTree"/>LinkTree</a>
                            </div>
                            <div *ngSwitchCase="'Instagram'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/instagram.svg" alt="Instagram"/>Instagram</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="image == null" class="row p-5">
            <div class="py-5 px-0 d-flex flex-column">
                <div *ngIf="title" class="row mb-2">
                    <h2 class="mt-md-0 mt-3" style="font-weight: bold;">{{ title }}</h2>
                </div>
                <div *ngIf="blocks && blocks.length > 0" class="row">
                    <div *ngFor="let block of blocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
                <div class="mx-4 my-3 pb-3">
                    <div *ngFor="let item of externalLinks">
                        <div [ngSwitch]="item.name">
                            <div *ngSwitchCase="'Facebook'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/facebook.svg" alt="Facebook"/>Facebook</a>
                            </div>
                            <div *ngSwitchCase="'LinkedIn'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/linkedin.svg" alt="LinkedIn"/>LinkedIn</a>
                            </div>
                            <div *ngSwitchCase="'Meetup'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/meetup.svg" alt="Meetup"/>Meetup</a>
                            </div>
                            <div *ngSwitchCase="'LinkTree'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/linktree.svg" alt="LinkTree"/>LinkTree</a>
                            </div>
                            <div *ngSwitchCase="'Instagram'">
                                <a class="my-2 d-inline-block text-decoration-none" [href]="item.link" rel="noopener"><img class="me-3" src="/assets/icons/instagram.svg" alt="Instagram"/>Instagram</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

