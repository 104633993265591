import { Component } from '@angular/core';
import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'split-root',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.scss']
})

export class SplitComponent extends BaseSectionComponent {

  name: string = "Split";
  componentGql: string = `... on ComponentContentSplit { title description button_text button_link linked_media { url  name alternativeText } image { formats url name alternativeText } image_styling { side fit } background_styling { color } }`;

  title!: string;
  subtitle!: string;
  description!: string;
  buttonText!: string;
  buttonLink!: string;
  side!: string;
  fit!: string;
  image: any = {};
  linkedMedia: any = {};

  blocks: any[] = [];
  
  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;
    this.subtitle = this.sectionData.subtitle;
    this.description = this.sectionData.description;
    this.buttonText = this.sectionData.button_text;
    this.buttonLink = this.sectionData.button_link;
    this.image = this.useAvailableImage(this.sectionData.image, ['M','L']),
    this.side = this.sectionData.image_styling?.side;
    this.fit = this.sectionData.image_styling?.fit;
    this.linkedMedia = this.sectionData.linked_media;

    this.blocks = this.description?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm);
    this.blocks = this.blocks?.filter(x => { return x });
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
