import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, SecurityContext } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { GraphQLModule } from './graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { markedOptionsFactory } from 'src/markdown-config';
import { cookieConfig } from 'src/cookie-config';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppComponent } from './app.component';
import { BasePageComponent } from './base/base-page/base-page.component';
import { BaseSectionComponent } from './base/base-section/base-section.component';
import { IntroComponent } from './sections/intro/intro.component';
import { MarkdownBlockComponent } from './sections/markdown-block/markdown-block.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { PageNamePipe } from './_pipes/page-name.pipe';
import { CollectionNamePipe } from './_pipes/collection-name.pipe';
import { MultipleFeaturedComponent } from './sections/multiple-featured/multiple-featured.component';
import { MultipleColoredBlocksComponent } from './sections/multiple-colored-blocks/multiple-colored-blocks.component';
import { FormattedDatePipe } from './_pipes/formatted-date.pipe';
import { UpcomingEventsComponent } from './sections/upcoming-events/upcoming-events.component';
import { FormattedDateTimePipe } from './_pipes/formatted-datetime.pipe';
import { LatestArticlesComponent } from './sections/latest-articles/latest-articles.component';
import { LatestNewsComponent } from './sections/latest-news/latest-news.component';
import { InstagramGalleryComponent } from './sections/instagram-gallery/instagram-gallery.component';
import { SplitComponent } from './sections/split/split.component';
import { ContinueFurtherComponent } from './sections/continue-further/continue-further.component';
import { BackgroundPipe } from './_pipes/background.pipe';
import { AnimateDirective } from './_directives/ng-animate.directive';
import { EventsComponent } from './sections/events/events.component';
import { ByoMapComponent } from './sections/byo-map/byo-map.component';
import { CityGuideMapComponent } from './sections/cityguide-map/cityguide-map.component';
import { ScriptService } from './_services/script.service';
import { FormComponent } from './sections/form/form.component';
import { FormItemComponent } from './base/form-item/form-item.component';
import { ButtonComponent } from './sections/button/button.component';
import { PartnersComponent } from './sections/partners/partners.component';
import { ScrollSpyDirective } from './_directives/ng-scroll-spy.directive';
import { DownloadsComponent } from './sections/downloads/downloads.component';
import { ArticlesComponent } from './sections/articles/articles.component';
import { AbsoluteRedirectComponent } from './base/absolute-redirect/absolute-redirect.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { CookieConsentService } from './_services/cookie-consent.service';
import { MatomoModule } from 'ngx-matomo';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { BetweenSectionImageComponent } from './sections/between-section-image/between-section-image.component';

@NgModule({
  declarations: [
    AppComponent,
    BasePageComponent,
    AbsoluteRedirectComponent,
    BaseSectionComponent,
    FormItemComponent,
    NavComponent,
    FooterComponent,
    IntroComponent,
    SplitComponent,
    ContinueFurtherComponent,
    MarkdownBlockComponent,
    MultipleFeaturedComponent,
    MultipleColoredBlocksComponent,
    UpcomingEventsComponent,
    LatestArticlesComponent,
    LatestNewsComponent,
    PartnersComponent,
    DownloadsComponent,
    InstagramGalleryComponent,
    EventsComponent,
    ArticlesComponent,
    ByoMapComponent,
    CityGuideMapComponent,
    FormComponent,
    ButtonComponent,
    BetweenSectionImageComponent,
    LoadingScreenComponent,
    FormattedDatePipe,
    FormattedDateTimePipe,
    BackgroundPipe,
    AnimateDirective,
    ScrollSpyDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    GraphQLModule,
    FontAwesomeModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    RecaptchaModule,
    MatomoModule,
    NgMultiSelectDropDownModule.forRoot(),
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    Meta,
    PageNamePipe,
    CollectionNamePipe,
    ScriptService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(cookieConsentService: CookieConsentService) {}
}
