import { Directive, ElementRef, HostListener, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import {extractElementPosition} from 'ng-html-util';

@Directive({
  selector: '[scroll-spy]'
})
export class ScrollSpyDirective {

  private elements: any[] = [];
  private currentActiveLink!: any;
  private directNavigation: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private renderer: Renderer2) {
  }

  public init(): void {
    this.collectIds();
  }

  private collectIds() {
    this.elements = [];
    let elements = this.el.nativeElement.querySelectorAll('a');

    for (let i = 0; i < elements.length; i++) {
      let elem = elements.item(i);

      let id = ScrollSpyDirective.getId(elem);

      if (!id)
        continue;

      let destination = this._getPeerElement(id);

      if (!destination)
        continue;

      elem.addEventListener('click', this._onLinkClicked.bind(this));

      this.elements.push({
        id,
        link: elem,
        destination
      })
    }
  }

  private _onLinkClicked(event: Event) {
    event.preventDefault();
    let target = event.currentTarget;
    let id = ScrollSpyDirective.getId(target);
    let destination: HTMLElement = this._getPeerElement(id);
    this.directNavigation = true;

    let position = extractElementPosition(this.document, destination);

    window.scrollTo({top: position.top - 25, left: 0, behavior: 'smooth'});

    this._cleanCurrentLink();
    this._setCurrentLink(target);
    this.directNavigation = false;
  }

  private _getPeerElement(id: string): HTMLElement {
    
    let destination: any = this.document.getElementById(id);

    return destination;
  }

  private static getId(elem: any) {
    let href = elem.getAttribute('href');

    if (!href)
      return null;

    return href.split('#')[1];
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll(event: Event) {
    if (this.directNavigation)
      return;

    for (let elem of this.elements) {
      let top = elem.destination.getBoundingClientRect().top;

      if (top > 0 && top < 40) {
        this._cleanCurrentLink();
        this._setCurrentLink(elem.link);
        break;
      }
    }
  }

  private _cleanCurrentLink() {
    if (!this.currentActiveLink)
      return;
    this.renderer.removeClass(this.currentActiveLink, 'active');
  }

  private _setCurrentLink(elem: EventTarget | null) {
    this.currentActiveLink = elem;
    this.renderer.addClass(this.currentActiveLink, 'active');
  }
}