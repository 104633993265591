import { NgcCookieConsentConfig } from "ngx-cookieconsent";

export const cookieConfig:NgcCookieConsentConfig = {
    cookie: {
      domain: location.origin // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    position: "bottom-left",
    palette: {
      popup: {
        background: '#2961f6'
      },
      button: {
        background: '#bde4ff'
      }
    },
    theme: 'edgeless',
    type: 'opt-out',
    content: {
        message: "This website uses cookies to ensure you get the best experience on our website.",
        dismiss: "Got it!",
        deny: "Refuse cookies",
        link: 'Learn more',
        href: location.origin + '/privacy-policy',
        policy: "Cookie Policy"
      }
  };