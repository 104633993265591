import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'PageNamePipe'})
export class PageNamePipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    console.log(value);
    switch(value){
      default:
        return value;
    };
  }
}