import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AbsoluteRedirectComponent } from './base/absolute-redirect/absolute-redirect.component';
import { BasePageComponent } from './base/base-page/base-page.component';
import { DataResolver } from './_helpers/data.resolver';

const routes: Routes = [
  { path: 'error', component: BasePageComponent },
  { path: '', component: BasePageComponent },

  {
    path: 'en/articles',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles' },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles' },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles' },
    pathMatch: 'full',
  },
  {
    path: 'en/articles',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles' },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/articles',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles' },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles' },
    pathMatch: 'full',
  },
  {
    path: 'sv/artiklar',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles' },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/zero-waste-in-stockholm-city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-in-stockholm-city-guide' },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/zero-waste-in-stockholm-city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-in-stockholm-city-guide' },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/zero-waste-in-stockholm-city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-in-stockholm-city-guide' },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/zero-waste-in-stockholm-city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-in-stockholm-city-guide' },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/how-to-make-habits-stick-what-to-do-now-plastic-free-july-is-over',
    },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/plastic-free-july-31-tips-tricks-to-reduce-your-plastic-waste',
    },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/zero-waste-zero-men-movement',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-zero-men-movement' },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/zero-waste-zero-men-movement',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-zero-men-movement' },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/zero-waste-zero-men-movement',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-zero-men-movement' },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/zero-waste-zero-men-movement',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/zero-waste-zero-men-movement' },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/varfor-kommer-sveriges-plastatervinningsgrad-att-delas-pa-tva-for-2020-och-med-fyra-for-2021',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/why-will-swedens-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/varfor-kommer-sveriges-plastatervinningsgrad-att-delas-pa-tva-for-2020-och-med-fyra-for-2021',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/why-will-swedens-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/why-will-sweden-s-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/why-will-swedens-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/why-will-sweden-s-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/why-will-swedens-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/articles/varfor-kommer-sveriges-plastatervinningsgrad-att-delas-pa-tva-for-2020-och-med-fyra-for-2021-1',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/why-will-swedens-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/articles/varfor-kommer-sveriges-plastatervinningsgrad-att-delas-pa-tva-for-2020-och-med-fyra-for-2021',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/why-will-swedens-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/varfor-kommer-sveriges-plastatervinningsgrad-att-delas-pa-tva-for-2020-och-med-fyra-for-2021',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/why-will-swedens-plastic-recycling-rate-be-divided-by-two-for-2020-and-by-four-for-2021',
    },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/bio-biodegradable-and-compostable-plastics-explained',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/bio-biodegradable-and-compostable-plastics-explained',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/bio-biodegradable-and-compostable-plastics-explained',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/bio-biodegradable-and-compostable-plastics-explained',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/new-study-shows-that-beverage-cartons-are-far-from-reaching-circularity',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/new-study-shows-that-beverage-cartons-are-far-from-reaching-circularity',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/new-study-shows-that-beverage-cartons-are-far-from-reaching-circularity',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/new-study-shows-that-beverage-cartons-are-far-from-reaching-circularity',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/bio-biodegradable-and-compostable-plastics-explained',
    },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/eu-s-engangsplastdirektiv',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/eu-engangsplastdirektiv-fokus-pa-minskad-forbrukning',
    },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/eu-s-engangsplastdirektiv',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/eu-engangsplastdirektiv-fokus-pa-minskad-forbrukning',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/eu-s-engangsplastdirektiv',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/eu-engangsplastdirektiv-fokus-pa-minskad-forbrukning',
    },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/eu-s-engangsplastdirektiv',
    component: AbsoluteRedirectComponent,
    data: {
      redirectTo:
        'articles/eu-engangsplastdirektiv-fokus-pa-minskad-forbrukning',
    },
    pathMatch: 'full',
  },

  {
    path: 'sv/resurser/articles/tgim',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/tgim' },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/artiklar/tgim',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/tgim' },
    pathMatch: 'full',
  },
  {
    path: 'en/articles/tgim',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/tgim' },
    pathMatch: 'full',
  },
  {
    path: 'en/resources/articles/tgim',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'articles/tgim' },
    pathMatch: 'full',
  },

  {
    path: 'byo',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/bring-your-own' },
    pathMatch: 'prefix',
  },
  {
    path: 'map',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/bring-your-own' },
    pathMatch: 'prefix',
  },
  {
    path: 'bring-your-own',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/bring-your-own' },
    pathMatch: 'prefix',
  },
  {
    path: 'en/projects/bring-your-own',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/bring-your-own' },
    pathMatch: 'prefix',
  },
  {
    path: 'sv/projekt/bring-your-own',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/bring-your-own' },
    pathMatch: 'prefix',
  },
  {
    path: 'en/projects/byo',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/bring-your-own' },
    pathMatch: 'prefix',
  },
  {
    path: 'sv/projekt/byo',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/bring-your-own' },
    pathMatch: 'prefix',
  },

  {
    path: 'city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/city-guide' },
    pathMatch: 'prefix',
  },
  {
    path: 'en/projects/city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/city-guide' },
    pathMatch: 'prefix',
  },
  {
    path: 'sv/projekt/city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/city-guide' },
    pathMatch: 'prefix',
  },
  {
    path: 'en/projects/city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/city-guide' },
    pathMatch: 'prefix',
  },
  {
    path: 'sv/projekt/city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/city-guide' },
    pathMatch: 'prefix',
  },
  {
    path: '/articles/zero-waste-in-stockholm-city-guide',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects/city-guide' },
    pathMatch: 'prefix',
  },
  {
    path: 'en/',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: '' },
    pathMatch: 'full',
  },
  {
    path: 'sv/',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: '' },
    pathMatch: 'full',
  },

  {
    path: 'en/about',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'about' },
    pathMatch: 'full',
  },
  {
    path: 'sv/om-oss',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'about' },
    pathMatch: 'full',
  },

  {
    path: 'en/events',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'events' },
    pathMatch: 'full',
  },
  {
    path: 'sv/evenemang',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'events' },
    pathMatch: 'full',
  },

  {
    path: 'en/projects',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects' },
    pathMatch: 'full',
  },
  {
    path: 'sv/projekt',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'projects' },
    pathMatch: 'full',
  },

  {
    path: 'en/get-involved',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'get-involved' },
    pathMatch: 'full',
  },
  {
    path: 'sv/engagera-dig',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'get-involved' },
    pathMatch: 'full',
  },

  {
    path: 'en/contact',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'contact' },
    pathMatch: 'full',
  },
  {
    path: 'sv/kontakt',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'contact' },
    pathMatch: 'full',
  },

  {
    path: 'en/support-us',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'support-us' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'support-us' },
    pathMatch: 'full',
  },

  {
    path: 'en/support-us/become-a-member',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'become-a-member' },
    pathMatch: 'full',
  },
  {
    path: 'support-us/become-a-member',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'become-a-member' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss/bli-medlem',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'become-a-member' },
    pathMatch: 'full',
  },

  {
    path: 'en/support-us/companies',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'our-partners' },
    pathMatch: 'full',
  },
  {
    path: 'support-us/companies',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'our-partners' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss/foretag',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'our-partners' },
    pathMatch: 'full',
  },

  {
    path: 'en/support-us/donate',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'donate' },
    pathMatch: 'full',
  },
  {
    path: 'support-us/donate',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'donate' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss/donera',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'donate' },
    pathMatch: 'full',
  },

  {
    path: 'en/resources/downloads',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'downloads' },
    pathMatch: 'full',
  },
  {
    path: 'resources/downloads',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'downloads' },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/dokument',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'downloads' },
    pathMatch: 'full',
  },

  {
    path: 'en/resources/frequently-asked-questions',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'frequently-asked-questions' },
    pathMatch: 'full',
  },
  {
    path: 'resources/frequently-asked-questions',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'frequently-asked-questions' },
    pathMatch: 'full',
  },
  {
    path: 'sv/resurser/vanliga-fragor',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'frequently-asked-questions' },
    pathMatch: 'full',
  },

  {
    path: 'en/support-us/become-a-member/hushallet',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/hushallet' },
    pathMatch: 'full',
  },
  {
    path: 'support-us/become-a-member/hushallet',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/hushallet' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss/bli-medlem/hushallet',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/hushallet' },
    pathMatch: 'full',
  },

  {
    path: 'en/support-us/become-a-member/unwrapped',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/unwrapped' },
    pathMatch: 'full',
  },
  {
    path: 'support-us/become-a-member/unwrapped',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/unwrapped' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss/bli-medlem/unwrapped',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/unwrapped' },
    pathMatch: 'full',
  },

  {
    path: 'en/support-us/become-a-member/we-are-lifelong',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/lifelong' },
    pathMatch: 'full',
  },
  {
    path: 'support-us/become-a-member/we-are-lifelong',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/lifelong' },
    pathMatch: 'full',
  },
  {
    path: 'en/support-us/become-a-member/lifelong',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/lifelong' },
    pathMatch: 'full',
  },
  {
    path: 'support-us/become-a-member/lifelong',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/lifelong' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss/bli-medlem/we-are-lifelong',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/lifelong' },
    pathMatch: 'full',
  },
  {
    path: 'sv/stodja-oss/bli-medlem/lifelong',
    component: AbsoluteRedirectComponent,
    data: { redirectTo: 'partners/lifelong' },
    pathMatch: 'full',
  },

  {
    path: '**',
    component: BasePageComponent,
    resolve: { myResolver: DataResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
