<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
  <div class="container px-4 py-5 my-5 my-5" >
    <h1 class="pb-4 mb-4 text-center fw-bold">{{ title }}</h1>
    <div class="row justify-content-md-center py-5 px-2">
      <div class="col-sm-6 px-2">
        <div *ngFor="let item of items; let i = index;">
          <div *ngIf="item.side == 'left'" class="animated-card my-5 p-3 rounded-0" [ngClass]="['border-start-' + item.color, item.text_color, (item.filled ? 'py-3 background-' + item.color : 'py-0')]">
            <h5 class="card-title fw-bold">{{ item.title }}</h5>
            <div *ngFor="let block of item.description">
              <div class="small" markdown emoji [data]="block"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 px-2">
        <div *ngFor="let item of items; let i = index;">
          <div *ngIf="item.side == 'right'" class="animated-card my-5 p-3 rounded-0" [ngClass]="['border-start-' + item.color, item.text_color, (item.filled ? 'py-3 background-' + item.color : 'py-0')]" >
            <h5 class="card-title fw-bold">{{ item.title }}</h5>
            <div *ngFor="let block of item.description">
              <div class="small" markdown emoji [data]="block"></div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
