<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
    <div class="container">
        <div *ngIf="image != null" class="row justify-content-md-center py-5 px-2">
            <div class="col-md-6" [ngClass]="{'order-md-2': side === 'right'}">
                <img *ngIf="image.url" class="img-fluid border-0" [ngClass]="{'img__contained': fit === 'contain', 'img__cover': fit === 'cover'}" src="{{ image.url }}" alt="{{ image.alternativeText }}" style="max-height: 25rem;">
            </div>
            <div class="col-md-6 order-1 d-flex flex-column">
                <div *ngIf="title" class="row mx-3 mb-2">
                    <h1 style="font-weight: bold;">{{ title }}</h1>
                </div>
                <div *ngIf="subtitle" class="row mx-3 mb-2">
                    <h3 style="font-weight: bold;">{{ subtitle }}</h3>
                </div>
                <div class="row mx-3">
                    <div *ngFor="let block of blocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
                <div *ngIf="buttonText" class="mx-4 mt-auto pb-3">
                    <a [routerLink]="buttonLink" class="btn btn-primary px-4 rounded-0 fw-bold">{{buttonText}}</a>
                </div>
            </div>
        </div>
        <div *ngIf="image == null" class="row p-5">
            <div class="py-5 px-0 d-flex flex-column">
                <div *ngIf="title" class="row mx-0 mb-2">
                    <h1 style="font-weight: bold;" class="ps-0">{{ title }}</h1>
                </div>
                <div *ngIf="subtitle" class="row mx-0 mb-2">
                    <h3 style="font-weight: bold;" class="ps-0">{{ subtitle }}</h3>
                </div>
                <div class="row">
                    <div *ngFor="let block of blocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
                <div *ngIf="buttonText" class="mx-4 mt-auto pb-3">
                    <a [routerLink]="buttonLink" class="btn btn-primary px-3 rounded-0">{{buttonText}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

