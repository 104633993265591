import { Injectable } from "@angular/core";
import { ScriptItem, ScriptStore } from "../models/script.store";

declare var document: any;

@Injectable()
export class ScriptService {

    private scripts: ScriptItem[] = [];

    constructor() {
        ScriptStore.forEach((script: ScriptItem) => {
            this.scripts.push(
                {
                    loaded: script.loaded,
                    src: script.src,
                    name: script.name
                }
            )
        });
    }

    load(...scripts: string[]) {
        scripts.forEach((script) => {
            console.log(script);
            this.loadScript(script)
        });
    }

    loadScript(name: string) {

        var isFound = false;
        var scripts = document.getElementsByTagName("script")
        for (var i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src')?.includes("loader")) {
                isFound = true;
            }
        }

        if (!isFound) {

            let script = this.scripts.filter(x => x.name == name)[0]

            let node = document.createElement('script');
            node.src = script.src;
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);

            script.loaded = true;
        }
    }

}