<div [formGroup]="form">
    <div *ngIf="field.type === 'text' || field.type === 'email' || field.type === 'number' || field.type === 'phone'" class="row form-floating mb-3 mx-2">
        <input 
            [type]="field.type" 
            [placeholder]="field.display" 
            [attr.required]="field.required" 
            [formControlName]="field.key" 
            [id]="field.key"
            [ngClass]="{'border-light': isEmpty, 'border-danger' : !isEmpty && !isValid, 'border-success' : !isEmpty && isValid }"
            class="form-control bg-transparent text-light border-start-0 border-top-0 border-end-0 rounded-0"
            >
        <label 
            [attr.for]="field.key"
            class="text-light px-0">
            {{field.display}}
        </label>
    </div>
    <div *ngIf="field.type === 'checkbox'" class="row form-floating mb-3 mx-2">
        <div class="form-check">
            <input 
                [type]="field.type" 
                [placeholder]="field.display" 
                [attr.required]="field.required" 
                [formControlName]="field.key" 
                [id]="field.key"
                (change)="checkboxValueChanged($event)"
                [ngClass]="{'border-light': isEmpty, 'border-danger' : !isValid, 'border-success' : isValid }"
                class="form-control bg-transparent px-0 text-light form-check-input"
                >
            <label 
                [attr.for]="field.key"
                class="text-light px-0 form-check-label small">
                {{field.display}}
            </label>
        </div>
    </div>
    <div *ngIf="field.type === 'textarea'" class="row form-floating mb-3 mx-2">
        <textarea 
            [placeholder]="field.display"
            [attr.required]="field.required" 
            [formControlName]="field.key" 
            [id]="field.key"
            [ngClass]="{'border-light': isEmpty, 'border-danger' : !isEmpty && !isValid, 'border-success' : !isEmpty && isValid }"
            class="form-control bg-transparent text-light border-start-0 border-top-0 border-end-0 rounded-0"
            style="height: 100px"
            >
        </textarea>
        <label 
            [attr.for]="field.key"
            class="text-light px-0">
            {{field.display}}
        </label>
    </div>
    <div *ngIf="field.type === 'single_choice_dropdown'" class="row form-floating mb-3 mx-2">
        <select 
            [attr.required]="field.required" 
            [formControlName]="field.key" 
            [id]="field.key"
            aria-label="Select an option"
            [ngClass]="{'border-light': isEmpty, 'border-danger' : !isEmpty && !isValid, 'border-success' : !isEmpty && isValid }"
            class="form-select custom-select bg-transparent text-light border-start-0 border-top-0 border-end-0 rounded-0"
            >
            <option *ngFor="let option of field.options" class="text-dark">{{option}}</option>
        </select>
        <label 
            [attr.for]="field.key"
            class="text-light">
            {{field.display}}
        </label>
    </div>
    <div *ngIf="field.type === 'multiple_choice_dropdown'" class="row mt-4 mb-3 mx-2">
        <label 
            [attr.for]="field.key" 
            class="mb-1 text-light px-0">
            {{field.display}}
        </label>
        <ng-multiselect-dropdown 
            class="text-light px-0"
            style="max-height: 100px;"
            [settings]="dropdownSettings"
            [data]="field.options"
            [formControlName]="field.key"
            enableCheckAll="false"
            [ngClass]="{'border-light': isEmpty, 'border-danger' : !isEmpty && !isValid, 'border-success' : !isEmpty && isValid }"
            >
        </ng-multiselect-dropdown>
    </div>
</div>
