import { Component } from '@angular/core';
import { MatomoInjector } from 'ngx-matomo';
import { RouteAnimations } from './_animations/animation';
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    RouteAnimations
  ]
})
export class AppComponent {
  constructor(private matomoInjector: MatomoInjector) {
    this.matomoInjector.init(environment.matomoEndpoint, 1);
   }
}
