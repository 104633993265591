import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from 'src/app/_services/language.service';

@Component({
	selector: "absolute-redirect",
	template:
	`<div>Redirecting to <code>{{ redirectTo }}</code></div>`
})
export class AbsoluteRedirectComponent implements OnInit {

	public redirectTo: string;

	constructor(
		activatedRoute: ActivatedRoute,
		private router: Router,
		private languageService: LanguageService) {
		this.redirectTo = activatedRoute.snapshot.data.redirectTo;
	}

	ngOnInit() {
		console.info( "Absolute redirect to:", this.redirectTo );

		let iso: string | null = null;

		if (this.router.url.indexOf('sv/') > -1) {
			iso = 'sv';
		}
		else if(this.router.url.indexOf('en/') > -1) {
			iso = 'en';
		}

		if(iso != null && this.languageService.getCurrentLanguage().iso != iso) {
			let language = this.languageService.languages.filter(x => x.iso == iso)[0];
			this.languageService.setLanguage(language);
		}

		// NOTE: We could have performed the .navigateByUrl() in the constructor.
		// However, doing so would have emitted a "navigation canceled" event. By waiting
		// until the init method, we allow the previous navigation to complete before we
		// start the new navigation. This feel more in alignment with the way the built-
		// in "redirectTo" property works.
		this.router.navigateByUrl( this.redirectTo, { replaceUrl: true } );
	}
}