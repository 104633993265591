import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { faFlag, faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

import { LanguageService } from '../_services/language.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApolloGqlCacheService } from '../_services/apollo-gql-cache.service';

const NavigationQuery = `{
   navigationItems (locale: "{locale}" where: { enabled: true, type: "header" }, sort: "order:asc") {
     name order color path is_child special navigation_items (sort: "order:asc") { name order path is_child }
  }
}`;

@Component({
  selector: 'nav-root',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, OnDestroy  {

  navigationItems: any = [];
  faGlobe = faGlobeEurope;
  faFlag = faFlag;
  languages: {name: string, iso: string; display: string, flag: string}[];
  currentLanguage: {name: string, iso: string; display: string, flag: string};
  routerSubscription!: Subscription;
  
  @ViewChild('navbarContent') navbarContent!:ElementRef;

  title: string = 'Zero Waste Stockholm'

  constructor(
    private apollo: ApolloGqlCacheService,
    private languageService: LanguageService,
    private router: Router,
    private renderer: Renderer2
    ) {
      this.languages = this.languageService.languages;
      this.currentLanguage = this.languageService.getCurrentLanguage();
      console.log("current language: " + this.currentLanguage.iso);
    }

  ngOnInit() {
    
    this.languageService.languageChanged.subscribe((value) => {
      this.currentLanguage = value;
      this.setNavgationItems();
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        if(!this.navbarContent.nativeElement.classList.contains('show')) {
          this.collapseNav();
        }
      }
    });
  }

  setNavgationItems() {

    let queryGql = NavigationQuery.replace('{locale}', this.currentLanguage.iso);

    this.apollo.query(queryGql).subscribe( (data: any) => {
      this.processResult(data) 
    });
  }

  processResult(data: any) {

    this.navigationItems = data.navigationItems.filter((x: { is_child: boolean }) => !x.is_child );
    console.log(this.navigationItems);
  }

  collapseNav() {
    this.renderer.removeClass(this.navbarContent.nativeElement, 'show');
  }

  changeLanguage(language: any) {
    let current = this.languageService.getCurrentLanguage();
    if(current != null && current.iso != language.iso) {
      this.languageService.setLanguage(language);
      window.location.reload();
    }
    console.log('changeLanguage: ' + language.iso);
  }

  ngOnDestroy() {
    this.languageService.languageChanged.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
