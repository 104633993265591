<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
  <div class="container px-4 py-5 my-5 my-5">
    <div class="row row-cols-1 row-cols-md-{{ numberPerRow }} g-4 justify-content-center">
      <div *ngFor="let item of items; let i = index;" class="col animated-card">
        <div class="card h-100 bg-transparent border-0">
          <a *ngIf="item.download" [href]="item.download.url" [attr.aria-label]="item.download.alternativeText" rel="noopener nofollow" target="_blank">
            <img *ngIf="item.image != null" class="card-img-top px-3" src="{{ item.image.url }}" alt="{{item.image.alternativeText}}" style="height: 200px; object-fit: cover;" loading="lazy">
          </a>
          <a *ngIf="item.buttonLink" [href]="item.buttonLink" aria-label="download" rel="noopener nofollow" target="_blank">
            <img *ngIf="item.image != null" class="card-img-top px-3" src="{{ item.image.url }}" alt="{{item.image.alternativeText}}" style="height: 200px; object-fit: cover;" loading="lazy">
          </a>
          <div class="card-body">
            <h5 class="card-title fw-bold mb-4">{{ item.title }}</h5>
            <div *ngFor="let block of item.description">
                <div class="card-text small" markdown emoji [data]="block"></div>
            </div>
          </div>
          <div *ngIf="item.buttonText" class="card-footer bg-transparent border-0">
            <a *ngIf="item.download" [href]="item.download.url"  class="btn btn-outline-primary rounded-0 border-2 fw-bolder" rel="noopener nofollow" target="_blank">{{ item.buttonText }}</a>
            <a *ngIf="item.buttonLink" [routerLink]="item.buttonLink" class="btn btn-outline-primary rounded-0 border-2 fw-bolder" rel="noopener nofollow" target="_blank">{{ item.buttonText }}</a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="buttonText && !isLastDownload" class="row mt-3">
      <div class="col text-center">
        <button *ngIf="!isLoading" type="button" class="btn btn-outline-primary rounded-0 border-2 fw-bolder mb-3" (click)="moreDownloads()" [disabled]="isLoading">{{ buttonText }}</button>
        <span *ngIf="isLoading" class="spinner-border border-5 text-primary fw-bolder mb-3" role="status" style="width: 3rem; height: 3rem;"></span>
      </div>
    </div>
  </div>
</div>
