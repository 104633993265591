<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
    <div class="container my-md-5">
        <div *ngIf="includeTable" class="row">
            <div class="col-md-3 mb-5">
                <nav data-toggle="toc" class="sticky-top">
                    <ul #scrollspy class="nav navbar-nav ms-md-3" scroll-spy>
                        <li *ngFor="let heading of headings">
                            <a class="nav-link" href="{{heading.href}}">{{heading.name}}</a>
                            <ul *ngIf="heading.children.length" class="nav navbar-nav">
                                <li *ngFor="let child of heading.children">
                                    <a class="nav-link" href="{{child.href}}">{{child.name}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-9">
                <div *ngIf="blocks" class="row justify-content-md-center mx-3 mb-2" style="position: relative;">
                    <div *ngFor="let block of blocks" class="w-100">
                        <div *ngIf="!isCodeBlock(block)" markdown emoji [data]="block" class="text-align-left markdown"></div>
                        <div *ngIf="isCodeBlock(block)" markdown emoji [data]="block" class="text-align-left"></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!includeTable" class="row">
            <div class="col-md-12">
                <div *ngIf="blocks" class="row justify-content-md-center mx-3 mb-2" style="position: relative;">
                    <div *ngFor="let block of blocks" class="w-100">
                        <div *ngIf="!isCodeBlock(block)" markdown emoji [data]="block" class="text-align-left markdown"></div>
                        <div *ngIf="isCodeBlock(block)" markdown emoji [data]="block" class="text-align-left"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
