import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import gql from 'graphql-tag';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'base-section-root',
  templateUrl: './base-section.component.html'
})

export class BaseSectionComponent implements OnInit, OnDestroy  {

  @Input() parentGql!: string;
  @Input() locale!: string;
  @Input() index!: number;
  @Input() sectionCount!: number;
  @Input() collectionType!: string;

  @Output() loadedEvent = new EventEmitter<string>();

  queryGql: any;
  componentGql!: string;
  collectionGql!: string;
  sectionData: any;
  collectionData: any;
  
  name!: string;
  background!: string;
  isLoaded: boolean = false;

  cmsUrl: string = environment.strapiEndpoint;

  isProtectedCollection: boolean = false;

  constructor(protected apollo: ApolloGqlCacheService) { }

  ngOnInit() {

    this.collectionGql = this.collectionGql?.replace('{locale}', this.locale);

    if(this.componentGql == null && this.collectionGql == null) {
      this.loadedEvent.emit(this.name);
      return;
    }

    let shellGql =  this.collectionGql 
      ? this.parentGql.replace('{ ', `{ ${this.collectionGql} `)
      : this.parentGql;

    this.queryGql = gql `${shellGql.replace( '__typename', this.componentGql )}`;

    console.log('shell gql: ' + shellGql)
    console.log('section gql: ' + this.queryGql.loc.source.body)
    console.log('index: ' + this.index + ' collection: ' + this.collectionType  + ' locale: ' + this.locale);

    this.apollo.query(this.queryGql).subscribe(
      (data: any) => { this.process(data) }
    )
  }

  process(data: any) {

    // console.log("section gql response: " + JSON.stringify(result, null, 2));

    this.sectionData =  data[this.collectionType][0].sections[this.index];
    this.collectionData = this.collectionGql ? data : null;
    
    this.assignData();

    this.loadedEvent.emit(this.name);

    this.isLoaded = true;
  }

  useAvailableImage(image: any, sizes: string[]) {

    if(image == null) {
      return null;
    }

    var result = { url: image.url, alternativeText: image.alternativeText != null ? image.alternativeText : image.name };
    
    for(var i = 0; i < sizes.length; i++) {
      switch(sizes[i]) {
        case 'S':
          if(image.formats.small?.url != null) {
            result.url = image.formats.small.url;
            return result;
          }
          break;
        case 'M':
          if(image.formats.medium?.url != null) {
            result.url = image.formats.medium.url;
            return result;
          }
          break;
        case 'L':
          if(image.formats.large?.url != null) {
            result.url = image.formats.large.url;
            return result;
          }
          break;
        case 'XS':
          if(image.formats.thumbnail?.url != null) {
            result.url = image.formats.thumbnail.url;
            return result;
          }
          break;
      }
    }
    return result;
  }

  ngOnDestroy() { }

  assignData() {

    let stylingBackground = this.sectionData.background_styling?.color;

    this.background = stylingBackground != null 
      ? stylingBackground 
      : (this.index % 2) == 0 
      ? "light" 
      : this.index == this.sectionCount - 1 
      ? "intermediate" 
      : "dark";
   }
}
