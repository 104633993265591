import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieStorageService } from './cookie-storage.service';

interface LanguageItem {
  name: string;
  iso: string;
  display: string;
  flag: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages: LanguageItem[];
  current: LanguageItem;
  public languageChanged!: BehaviorSubject<LanguageItem>;

  constructor(private tokenStorageService: CookieStorageService) {
    this.languages = [{name: "English", iso: "en", display: "EN", flag: "gb"}, {name: "Swedish", iso: "sv", display: "SV", flag: "se"}];

    let storedLanguague: LanguageItem | null = null;

    try {
      storedLanguague = JSON.parse(this.tokenStorageService.getLanguage());
    }
    catch { }
  
    if(storedLanguague != null && storedLanguague.iso != null) {
      this.current = storedLanguague;
    }
    else {
      this.tokenStorageService.setLanguage(JSON.stringify(this.languages[0]));
      this.current = this.languages[0];
    }

    this.languageChanged = new BehaviorSubject(this.current);
  }

  getCurrentLanguage(): LanguageItem {
    return this.current != null ? this.current : this.languages[0];
  }
  
  setLanguage(value: LanguageItem) {

    this.tokenStorageService.setLanguage(JSON.stringify(value));
    this.current = value;
    this.languageChanged.next(value);
  }
}