import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo';
import { ApolloGqlCacheService } from 'src/app/_services/apollo-gql-cache.service';
import { LanguageService } from 'src/app/_services/language.service';
import { LoadingService } from 'src/app/_services/loading.service';

@Component({
  selector: 'base-page-root',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})

export class BasePageComponent implements OnInit, OnDestroy  {

  collectionType!: string;
  collectionItem!: string;
  type: string = '';
  sections: any = [];
  parentGql!: string;
  locale!: string;
  loadedCount: number = 0;
  isLoaded: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private apollo: ApolloGqlCacheService, 
    private loadingService: LoadingService,
    private languageService: LanguageService,
    private matomoTracker: MatomoTracker) {
    
      var brokenUrl = this.router.url.split('%').length > 1;
      if(brokenUrl) {
        this.router.navigateByUrl(this.router.url.split('%')[0], { replaceUrl: true });
      }
      
      this.loadingService.setValue(false);
  }

  ngOnInit() {

    let queryGql = this.prepareQuery();

    this.fetchData(queryGql);

    this.matomoTracker.trackPageView(this.router.url);
    this.matomoTracker.enableLinkTracking(true);
  }

  prepareQuery(): string {

    let primary = this.activatedRoute.snapshot.url[0]?.path;
    let secondary = this.activatedRoute.snapshot.url[1]?.path;

    this.collectionType = secondary == null ? 'pages' : primary;
    this.collectionItem = secondary == null ? primary == null ? 'home' : primary : secondary;

    console.log('collection: ' + this.collectionType);
    console.log('collectionItem: ' + this.collectionItem);

    this.locale = this.languageService.getCurrentLanguage().iso;

    console.log(this.locale);

    this.parentGql = `{ ${this.collectionType} (where: { uid: "${this.collectionItem}"}, locale: "${this.locale}") { sections { __typename } } }`;
    
    return this.parentGql;
  }

  fetchData(queryGql: string): void {

    this.apollo.query(queryGql).subscribe(
      (data: any) => { this.process(data) },
      (error: any) => { this.router.navigate(['error']) }
    )
  }

  process(data: any) {

    if(data[this.collectionType].length != 1) {
      this.router.navigate(['error']);
      return;
    }

    let pageData = data[this.collectionType][0];

    console.log("page gql response: " + JSON.stringify(pageData, null, 2));

    var counter = 0;

    if(pageData == null) {
      return;
    }

    pageData.sections.forEach( (section: { __typename: string; section: { type: any; }; }) => 
    {
      this.sections.push( { type: section.__typename, index: counter } )
      
      counter++
    })

    console.log("sections: ")
    console.log(this.sections)
  }

  sectionLoaded( section : any ) {
    this.loadedCount++;
    console.log(`${section} loaded.`);
    if(this.loadedCount == this.sections.length){ 
      this.isLoaded = true;
      this.loadingService.setValue(true);
      console.log('All sections loaded.')
    }
  }

  ngOnDestroy() { }
}

