import { Component, ViewChild } from '@angular/core';
import { ScrollSpyDirective } from 'src/app/_directives/ng-scroll-spy.directive';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'markdown-block-root',
  templateUrl: './markdown-block.component.html',
  styleUrls: ['./markdown-block.component.scss'],
  preserveWhitespaces: true
})

export class MarkdownBlockComponent extends BaseSectionComponent {

  name: string = "MarkdownBlock";
  componentGql: string = `... on ComponentContentMarkdownBlock { content include_table background_styling { color } }`;

  text: string = "";
  blocks: any[] = [];
  headings: any[] = [];
  includeTable!: boolean;

  @ViewChild(ScrollSpyDirective)
  set scrollSpy(scrollSpy: ScrollSpyDirective) { scrollSpy?.init(); };

  ngOnInit() { super.ngOnInit(); }

  assignData() {
    
    super.assignData();
    
    this.text = this.sectionData.content;
    this.includeTable = this.sectionData.include_table;
    this.blocks = this.text?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm);
    this.blocks = this.blocks?.filter(x => { return x });

    this.text.match(/^(#{2,6}\s*[\S ]+)/gm)?.forEach((x) => {

      var index = x.match(/\[(\d+?)\]/);
      var name = x.replace(/^(#{2,6}\s)| \[(\d*?)\]/gm, '');
      var href = `${location.pathname}#${name.toLowerCase().replace(/[^\w]+/g, '-').replace(/((-br-)$)|(-$)/, "")}${ (index != null && index[1] != null) ? '-' + index[1] : ''}`;
      var isParent = (x.match(/#/g) || []).length == 2;
      
      if(isParent) { this.headings.push( { name: name, href: href, children: [] } ); }
      else { this.headings[this.headings.length - 1].children.push( { name: name, href: href, children: null } ); }
    });

    console.log(this.headings);
  }

  isCodeBlock(block: string) {
    return block.includes('```');
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
