<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
  <div class="container px-4 pt-5 pb-3">
    <h1 class="pb-4 mb-4 text-center fw-bold">{{ title }}</h1>
  </div>
  <div class="instagram__grid w-100 h-100 pt-2 pb-5 justify-content-center overflow-hidden">
    <div *ngFor="let item of items; let i = index;" class="instagram__item w-100 h-100 overflow-hidden">
      <a href="{{item.permalink}}" class="link__zoom__active" aria-label="Instagram media" target="_blank" rel="noopener nofollow">
        <img class="w-100 h-100 instagram__image image__zoomable zoom" src="{{ item.url }}" alt="{{item.name}}" loading="lazy">
      </a>
    </div>
  </div>
</div>
