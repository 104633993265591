import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'BackgroundPipe'})
export class BackgroundPipe implements PipeTransform {
  transform(value: any): string {

    if(!value) {
      return `background-color: #fff;`;
    }

    if(typeof(value) == "string") {
      switch(value) {
        default:
        case 'light':
          return `background-color: #ffffff;`;
        case 'dark':
          return `background-color: #f8f8f8`;
        case 'intermediate':
          return `background-image: linear-gradient(to bottom, #f8f8f8, #ffffff)`;
        case 'special':
          return `background-color: #2961f6;`;
      }
    }
    else {
      return `background-image: url('${value.url}'); background-repeat: no-repeat; background-position: center; background-size: cover;`;
    }
  }
}