import { Component } from '@angular/core';
import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'between-section-image-root',
  templateUrl: './between-section-image.component.html',
  styleUrls: ['./between-section-image.component.scss']
})

export class BetweenSectionImageComponent extends BaseSectionComponent  {

  name: string = "BetweenSectionImage";
  componentGql: string = `... on ComponentContentBetweenSectionImage { image { formats url name alternativeText } }`;

  image!: any;
  
  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.image = this.sectionData.image;
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
