import { Component } from '@angular/core';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'button-root',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent extends BaseSectionComponent  {

  name: string = "Button";
  componentGql: string = `... on ComponentContentButton { text link background_styling { color } }`;

  buttonLink!: string;
  buttonText!: string;
  
  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.buttonText = this.sectionData.text;
    this.buttonLink = this.sectionData.link;
  }

  ngOnDestroy() { super.ngOnDestroy() }
}
