import { Injectable, OnDestroy } from '@angular/core';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { LanguageService } from './language.service';
import { CookieStorageService } from './cookie-storage.service';

@Injectable({
  providedIn: 'root'
})

export class CookieConsentService implements OnDestroy {

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializeSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(private cookieStorageService: CookieStorageService, private ccService: NgcCookieConsentService, private languageService: LanguageService) { 
    
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe( () => { console.log('init') });
    this.popupCloseSubscription = this.ccService.popupClose$.subscribe( () => { });
    this.initializeSubscription = this.ccService.initialize$.subscribe( (event: NgcInitializeEvent) => { });
    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe( () => { });
    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe( (event: NgcNoCookieLawEvent) => { });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe( (event: NgcStatusChangeEvent) => {
      switch(event.status) {
        case 'deny':
          this.cookieStorageService.setCookieAllowance(false);
          break;
        case 'allow':
          this.cookieStorageService.setCookieAllowance(true);
          break;
      }
    });

    let cookieAllowance = this.cookieStorageService.getCookieAllowance();
    if(cookieAllowance == 'true' || cookieAllowance == 'false') {
      this.ccService.fadeOut();
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}