import { Component } from '@angular/core';
import { FeaturedItem } from 'src/app/models/featured-item';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'multiple-featured-root',
  templateUrl: './multiple-featured.component.html',
  styleUrls: ['./multiple-featured.component.scss'],
})
export class MultipleFeaturedComponent extends BaseSectionComponent {
  name: string = 'Multiple Featured';
  componentGql: string = `... on ComponentContentMultipleFeatured { title button_text button_link number_per_row background_styling { color } cards { ... on ComponentItemCard { title subtitle description button_text button_link image_fit image { formats url name alternativeText } } } }`;

  title: string = '';
  num: string = '';
  items: FeaturedItem[] = [];

  count!: number;
  itemsPerRow!: number;

  ngOnInit() {
    super.ngOnInit();
  }

  assignData() {
    super.assignData();

    this.title = this.sectionData.title;

    this.sectionData.cards.forEach(
      (item: {
        title: string;
        subtitle: string;
        description: string;
        button_text: string;
        button_link: string;
        published: string;
        image: any;
        image_fit: string;
      }) => {
        this.items.push({
          title: item.title,
          subtitle: item.subtitle,
          description: item.description
            ?.split(/(`{3}[\w]*\n[\S\s]+?`{3}\n?)/gm)
            .filter((x) => {
              return x;
            }),
          image:
            this.sectionData.cards?.length == 1
              ? this.useAvailableImage(item.image, ['L'])
              : this.useAvailableImage(item.image, ['S', 'M', 'L']),
          buttonText: item.button_text,
          buttonLink: item.button_link,
          publication: item.published,
          imageFit: item.image_fit,
        });
      }
    );

    this.count = this.items.length;
    this.itemsPerRow =
      this.sectionData.number_per_row != null
        ? this.sectionData.number_per_row
        : Math.min(this.items.length, 3);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
