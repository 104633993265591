import { Component, OnInit } from '@angular/core';
import { faLinkedin, faInstagram, faFacebook, faMeetup } from '@fortawesome/free-brands-svg-icons';
import { ApolloGqlCacheService } from '../_services/apollo-gql-cache.service';
import { LanguageService } from '../_services/language.service';
import { LoadingService } from '../_services/loading.service';

const NavigationQuery = `{
  navigationItems (locale: "{locale}" where: { enabled: true, type: "footer" }, sort: "order:asc") { 
    name order path is_child navigation_items { name order path is_child } 
  } 
}`;

@Component({
  selector: 'footer-root',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  name: string = "Footer";
  
  faFacebook = faFacebook;
  faLinkedin = faLinkedin;
  faInstagram = faInstagram;
  faMeetup = faMeetup;
  
  data: any = {};
  items: any = [];
  currentLanguage: {name: string, iso: string; display: string, flag: string};

  isVisible: boolean = false;

  constructor(
    private apollo: ApolloGqlCacheService,
    private languageService: LanguageService,
    private loadingService: LoadingService) {
    loadingService.isLoaded.subscribe((value) => { this.isVisible = value; })
    this.currentLanguage = this.languageService.getCurrentLanguage();
    console.log("current language: " + this.currentLanguage.iso);
  }

  ngOnInit() {
    
    this.languageService.languageChanged.subscribe((value) => {
      this.currentLanguage = value;
      this.setNavgationItems();
    });
  }

  setNavgationItems() {

    let queryStr = `${NavigationQuery.replace('{locale}', this.currentLanguage.iso)}`;

    this.apollo.query(queryStr).subscribe( (data: any) => {
      this.items = data.navigationItems.filter((x: { special: boolean; is_child: boolean }) => !x.is_child);
    });
  }

  ngOnDestroy() {
    this.languageService.languageChanged.unsubscribe();
    this.loadingService.isLoaded.unsubscribe();
  }
}
