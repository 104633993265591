<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
    <div class="container">
        <div *ngIf="image != null" class="row justify-content-md-center py-5 px-2">
            <div class="col-md-6 text-center" [ngClass]="{'order-md-2': side === 'right'}">
                <a *ngIf="buttonLink" [routerLink]="buttonLink" class="d-inline-block">
                    <img *ngIf="image.url" class="img-fluid border-0 animated-card" [ngClass]="{'img__contained': fit === 'contain', 'img__cover': fit === 'cover'}" src="{{ image.url }}" alt="{{ image.alternativeText }}" loading="lazy">
                </a>
                <a *ngIf="linkedMedia" href="{{ linkedMedia.url }}" target="_blank" rel="noopener" class="d-inline-block">
                    <img *ngIf="image.url" class="img-fluid border-0 animated-card" [ngClass]="{'img__contained': fit === 'contain', 'img__cover': fit === 'cover'}" src="{{ image.url }}" alt="{{ image.alternativeText }}" loading="lazy">
                </a>
                <img *ngIf="!buttonLink && !linkedMedia && image.url" class="img-fluid border-0" [ngClass]="{'img__contained': fit === 'contain', 'img__cover': fit === 'cover'}" src="{{ image.url }}" alt="{{ image.alternativeText }}" loading="lazy">
            </div>
            <div class="col-md-6 order-md-1 d-flex flex-column">
                <div *ngIf="title" class="row mx-3 mb-2">
                    <h2 class="mt-md-0 my-3" style="font-weight: bold;">{{ title }}</h2>
                </div>
                <div *ngIf="blocks && blocks.length > 0" class="row mx-3">
                    <div *ngFor="let block of blocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
                <div *ngIf="buttonText" class="mx-4 mt-4 pb-3">
                    <a *ngIf="buttonLink" [routerLink]="buttonLink" class="btn btn-primary rounded-0 border-2 fw-bolder">{{buttonText}}</a>
                    <a *ngIf="linkedMedia" href="{{ linkedMedia.url }}" class="btn btn-outline-primary rounded-0 border-2 fw-bolder" rel="noopener nofollow" target="_blank">{{buttonText}}</a>
                </div>
            </div>
        </div>
        <div *ngIf="image == null" class="row p-5">
            <div class="py-5 px-0 d-flex flex-column">
                <div *ngIf="title" class="row mb-2">
                    <h2 class="mt-md-0 my-3" style="font-weight: bold;">{{ title }}</h2>
                </div>
                <div *ngIf="blocks && blocks.length > 0" class="row">
                    <div *ngFor="let block of blocks">
                        <div class="markdown" markdown emoji [data]="block"></div>
                    </div>
                </div>
                <div *ngIf="buttonText && buttonLink" class="mx-4 mt-auto pb-3">
                    <a *ngIf="buttonLink" [routerLink]="buttonLink" class="btn btn-outline-primary rounded-0 border-2 fw-bolder">{{buttonText}}</a>
                    <a *ngIf="!buttonLink.includes('mailto:')" [routerLink]="buttonLink" class="btn btn-outline-primary rounded-0 border-2 fw-bolder" rel="noopener nofollow" target="_blank">{{ buttonText }}</a>
                </div>
                <div *ngIf="buttonText && linkedMedia" class="mx-4 mt-auto pb-3">
                    <a *ngIf="linkedMedia" href="{{ linkedMedia.url }}" class="btn btn-primary rounded-0 border-2 fw-bolder" rel="noopener nofollow" target="_blank">{{buttonText}}</a>
                </div>
            </div>
        </div>
    </div>
</div>

