import { trigger, animate, style, group, query as q, transition, AnimationMetadata } from '@angular/animations';

const query = (s: string, a: AnimationMetadata | AnimationMetadata[], o = { optional: true }) => q(s, a, o);

export const RouteAnimations = trigger('routeAnimations', [
  transition('* <=> blogPost',  [
    query(':enter, :leave', style({ position: 'fixed', width:'100%',height:'100%' })),
    query(':enter', style({ transform: 'translateY(100%)' })),
    
    group([
      query(':leave', [
        style({ transform: 'translateY(0%)' }),
        animate('1.0s 1.0s ease-in-out', style({transform: 'translateY(100%)'}))
      ]),
      query(':enter', [
        animate('1.0s 1.0s ease-in-out', style({transform: 'translateY(0%)'})),
      ])
    ]),
  ]),
  transition('* <=> article',  [
    query(':enter, :leave', style({ position: 'fixed', width:'100%',height:'100%' })),
    query(':enter', style({ transform: 'translateY(100%)' })),
    
    group([
      query(':leave', [
        style({ transform: 'translateY(0%)' }),
        animate('1.0s 1.0s ease-in-out', style({transform: 'translateY(100%)'}))
      ]),
      query(':enter', [
        animate('1.0s 1.0s ease-in-out', style({transform: 'translateY(0%)'})),
      ])
    ]),
  ]),
  transition('any => any', [
    query(':enter, :leave', style({ position: 'fixed', width:'100%',height:'100%' })),
    query(':enter', style({ transform: 'translateX(100%)' })),
    
    group([
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('1.0s 1.0s ease-in-out', style({transform: 'translateX(-100%)'}))
      ]),
      query(':enter', [
        animate('1.0s 1.0s ease-in-out', style({transform: 'translateX(0%)'})),
      ])
    ]),
  ]),
]);
