<div class="container__banner" [style]="background | BackgroundPipe">
  <div class="container px-4 py-5 my-5 my-5 my-3">
    <h1 class="pb-4 mb-4 text-center fw-bold">{{ title }}</h1>
    <div *ngIf="items == null || items.length == 0">
      <p class="text-center mb-5">{{noEventsText}}</p>
    </div>
    <div *ngIf="items != null && items.length > 0" class="row row-cols-1 row-cols-md-{{ numberPerRow }} g-4 justify-content-center">
      <div *ngFor="let item of items; let i = index;" class="col event">
        <img *ngIf="item.image != null" class="card-img-top px-3" src="{{ item.image.url }}" alt="{{item.image.alternativeText}}" loading="lazy" style="max-height: 200px; object-fit: cover;">
        <div class="card-body">
          <h5 class="card-title fw-bold mb-4 mt-3">{{ item.name }}</h5>
          <p class="mb-2">{{ item.start | FormattedDateTimePipe }}</p>
          <p class="card-text">{{ item.shortDescription }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="buttonText" class="row mt-3">
      <div class="col text-center">
        <a [routerLink]="buttonLink" class="btn btn-outline-primary rounded-0 border-2 fw-bolder">{{ buttonText }}</a>
      </div>
    </div>
  </div>
</div>
