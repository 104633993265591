<div *ngIf="isLoaded" class="container__banner" [style]="background | BackgroundPipe">
  <div class="container px-4 py-5 my-5 my-5">
    <h1 class="d-block text-center mb-1" style="font-weight: bold;">{{ featuredTitle }}</h1>
    <span class="d-block text-center mb-5 small" style="cursor: pointer;" (click)="switchSorting()">{{ currentSorting == "desc" ? sorting?.desc.translation : sorting?.asc.translation }}<fa-icon class="ms-2" [icon]="currentSorting == 'desc' ? faCaretDown : faCaretUp" ></fa-icon></span>
    <div class="row row-cols-1 row-cols-md-{{ numberPerRow }} g-4 justify-content-center">
      <div *ngFor="let item of items; let i = index;" class="col animated-card mt-5">
        <div class="card h-100 bg-transparent border-0">
          <a [routerLink]="[item.uid]" [attr.aria-label]="item.uid">
            <img *ngIf="item.image != null" class="card-img-top px-3" src="{{ item.image.url }}" alt="{{item.image.alternativeText}}" loading="lazy" style="height: 200px; object-fit: cover;">
          </a>
          <div class="card-body">
            <h5 class="card-title fw-bold mb-4">{{ item.title }}</h5>
            <p class="card-text small">{{ item.introduction }} <a [routerLink]="[item.uid]" aria-label="read-more">{{ item.buttonText }}</a></p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="buttonText && !isLastArticle" class="row mt-3">
      <div class="col text-center">
        <button *ngIf="!isLoading" type="button" class="btn btn-outline-primary rounded-0 border-2 fw-bolder mb-3" (click)="moreArticles()" [disabled]="isLoading">{{ buttonText }}</button>
        <span *ngIf="isLoading" class="spinner-border border-5 text-primary fw-bolder mb-3" role="status" style="width: 3rem; height: 3rem;"></span>
      </div>
    </div>
  </div>
</div>
