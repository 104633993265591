import { Component } from '@angular/core';
import { instagramtItem } from 'src/app/models/instagram-item';

import { BaseSectionComponent } from '../../base/base-section/base-section.component';

@Component({
  selector: 'instagram-gallery-root',
  templateUrl: './instagram-gallery.component.html',
  styleUrls: ['./instagram-gallery.component.scss']
})

export class InstagramGalleryComponent extends BaseSectionComponent  {

  name: string = "Instagram Gallery";
  collectionGql: string = `instagramMedias(limit: 8, sort: "date:desc") { permalink date media_type url image { url } }`;
  componentGql: string = `... on ComponentContentInstagramGallery { title number_medias }`;

  title!: string;
  numberMedias!: number;

  items: instagramtItem[] = [];

  ngOnInit() { super.ngOnInit() }

  assignData() {

    super.assignData();

    this.title = this.sectionData.title;
    this.numberMedias = this.sectionData.number_medias;

    this.collectionData.instagramMedias.forEach( (media: { permalink: string; image:any, url: string, date: string; }) => {
      this.items.push({
        permalink: media.permalink,
        image: media.image,
        url: media.url,
        date: media.date,
        name: `Instagram ZWS ${media.date}`
      })
    })
  }
  
  ngOnDestroy() { super.ngOnDestroy() }
}
